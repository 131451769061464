import { flux } from '@/core/flux.module';

export function setAssetGroupEditorVisible(visible: boolean) {
  flux.dispatch('LAYOUT_SET_ASSET_GROUP_EDITOR_VISIBLE', { visible });
}

export function setTableDefinitionEditorVisible(visible: boolean) {
  flux.dispatch('LAYOUT_SET_TABLE_DEFINITION_EDITOR_VISIBLE', { visible });
}

export function setToolsTabVisible(visible: boolean) {
  flux.dispatch('LAYOUT_SET_TOOLS_TAB_VISIBLE', { visible });
}

export function setDisplayPaneAddOnCapsulePanelEnabled(enabled: boolean) {
  flux.dispatch('LAYOUT_SET_DISPLAY_PANE_ADD_ON_CAPSULES_PANEL_ENABLED', { enabled });
}

export function setDisplayPaneAddOnDetailsPanelEnabled(enabled: boolean) {
  flux.dispatch('LAYOUT_SET_DISPLAY_PANE_ADD_ON_DETAILS_PANEL_ENABLED', { enabled });
}

export function setTrendVisible(visible: boolean) {
  flux.dispatch('LAYOUT_SET_TREND_VISIBLE', { visible });
}

export function setAutoFocusToolsTab(autofocus: boolean) {
  flux.dispatch('LAYOUT_SET_AUTO_FOCUS_TOOLS_TAB', { autofocus });
}

export function setAutoFocusDataTab(autofocus: boolean) {
  flux.dispatch('LAYOUT_SET_AUTO_FOCUS_DATA_TAB', { autofocus });
}

export function resetLayout(reset: boolean) {
  flux.dispatch('LAYOUT_RESET', { reset });
}
export function resetJournalToolbar(reset: boolean) {
  flux.dispatch('LAYOUT_RESET_JOURNAL_TOOLBAR', { reset });
}

export function setJournalTabVisible(visible: boolean) {
  flux.dispatch('LAYOUT_SET_JOURNAL_TAB_VISIBLE', { visible });
}

export function setSidePanelCollapsed(collapsed: boolean) {
  flux.dispatch('LAYOUT_SET_SIDE_PANEL_COLLAPSED', { collapsed });
}

export function setLayoutLocked(locked: boolean) {
  flux.dispatch('LAYOUT_SET_LAYOUT_LOCKED', { locked });
}

export function setFormulaInTab(inTab: boolean) {
  flux.dispatch('LAYOUT_SET_FORMULA_IN_TAB', { inTab });
}
export function setFormulaTabAnchorPosition({
  top,
  left,
  height,
  width,
}: {
  top: number;
  left: number;
  height: number;
  width: number;
}) {
  flux.dispatch('LAYOUT_SET_FORMULA_TAB_ANCHOR_POSITION', { top, left, height, width });
}
export function setFormulaToolSize({ height, width }: { height: number; width: number }) {
  flux.dispatch('LAYOUT_SET_FORMULA_TOOL_SIZE', { height, width });
}

export function setCalculationHierarchyInTab(inTab: boolean) {
  flux.dispatch('LAYOUT_SET_CALCULATION_HIERARCHY_IN_TAB', { inTab });
}
