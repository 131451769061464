import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { sqImpactReportStore } from '@/core/core.stores';
import { useNavigate, useParams } from 'react-router-dom';
import { ImpactReportEditModal } from '@/impactReports/ImpactReportEditModal.page';
import { useAsyncEffect } from 'rooks';
import { setForceTableReloadFlag } from '@/impactReports/impactReport.actions';
import { AnyProperty } from '@/utilities.types';
import { ImpactReportDetailModal } from '@/impactReports/ImpactReportDetailModal.page';
import { ImpactReportUseCase, TRACK_IMPACT } from '@/impactReports/impactReport.types';
import { doTrack } from '@/track/track.service';
import { getImpactReportForWorksheet } from '@/impactReports/impactReport.utilities';

import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { setDisplayValueCaptureEditModal } from '@/impactReports/impactReport.actions';

export const ImpactReportMenu: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { workbookId, worksheetId } = useParams();
  const { t } = useTranslation();
  const [existingReport, setExistingReport] = useState<AnyProperty[] | undefined>(undefined);
  const displayEditModal = useFluxPath(sqImpactReportStore, () => sqImpactReportStore.displayEditModal);
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);

  useAsyncEffect(async () => {
    if (displayEditModal) return;
    setExistingReport(await getImpactReportForWorksheet(workbookId, worksheetId));
  }, [workbookId, worksheetId, displayEditModal]);

  const actions = [
    {
      href: '#',
      iconClass: 'fa-pencil',
      action: () => {
        // if an impact reports exists we open the detail modal, if not we open the edit modal to create a new one
        if (existingReport) {
          doTrack(TRACK_IMPACT, 'View existing Impact Report', 'from Toolbar menu');
          setDisplayDetailsModal(true);
        } else {
          setDisplayValueCaptureEditModal(true);
          doTrack(TRACK_IMPACT, 'Create new Impact Report', 'from Toolbar menu');
        }
      },
      translationKey: existingReport ? 'IMPACT_REPORT.VIEW' : 'IMPACT_REPORT.NEW',
    },
    {
      href: '#',
      iconClass: 'fa-lightbulb-dollar',
      action: () => navigate('/impact'),
      translationKey: 'IMPACT_REPORT.VIEW_ALL',
    },
  ];

  const generateDropdownEntry = ({ iconClass = '', translationKey = '', action = _.noop, testId = '' }) => {
    return {
      key: `dropdown_${translationKey}`,
      icon: iconClass,
      containerExtraClassNames: 'sq-force-text-gray forceFont',
      labelTestId: `moreActions_${translationKey}`,
      iconTestId: `moreActionsIcon_${translationKey}`,
      onClick: action,
      label: t(translationKey),
      testId,
    };
  };

  return (
    <>
      <ButtonWithDropdown
        id="valueCaptureMenu"
        keepFocusInsideDropdown={false}
        triggerIcon={
          <Icon
            icon="fa-lightbulb-dollar"
            extraClassNames="fa-xlg sq-navbar-default flexNoGrowNoShrink mb1"
            type="white"
            testId="valueCaptureMenu"
            tooltip={t('IMPACT_REPORT.IMPACT_REPORT')}
            tooltipPlacement="bottom"
            iconPrefix={existingReport ? 'fa-solid' : ''}
          />
        }
        dropdownItems={actions.map((action) => generateDropdownEntry(action))}
      />
      {displayDetailsModal && (
        <ImpactReportDetailModal
          closeFn={() => setDisplayDetailsModal(false)}
          details={existingReport as unknown as ImpactReportUseCase}
          editCallbackFn={() => {
            setDisplayDetailsModal(false);
            setDisplayValueCaptureEditModal(true);
          }}
        />
      )}
      {displayEditModal && (
        <ImpactReportEditModal
          existingReport={existingReport}
          closeFn={(reload = false) => {
            if (reload && window.location.pathname === '/impact') {
              setForceTableReloadFlag(true);
            }
            setDisplayValueCaptureEditModal(false);
          }}
        />
      )}
    </>
  );
};
