import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import {
  cancelAllServerRequests as cancelAllServerRequestsUtils,
  cancelCurrentUserServerRequests as cancelCurrentUserServerRequestsUtils,
} from '@/requests/pendingRequests.utilities';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { SupportRequestModal } from '@/supportRequest/SupportRequestModal';
import { successToast, warnToast } from '@/utilities/toast.utilities';
import { sqHomeScreenStore, sqLicenseManagementStore } from '@/core/core.stores';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';
import { emitAllServerRequestsCanceled } from '@/services/notifier.service';
import {
  canReadAuditTrail,
  hasAnalyticsAdminCapability,
  isAdmin,
  showAdministrationMenuItem,
} from '@/services/authorization.service';
import { setShowModal } from '@/core/hooks/useModalManager.hook';
import { setTrainingPromptDisplay } from '@/homescreen/homescreen.actions';
import { TrainingPromptDisplay } from '@/homescreen/homescreen.constants';
import { ReleaseNotesModal } from '@/header/ReleaseNotesModal';
import { releaseNotesEnabled } from '@/services/systemConfiguration.utilities';
import { doTrack } from '@/track/track.service';
import { TrainingPromptModal } from '@/homescreen/TrainingPromptModal.molecule';

interface HamburgerMenuIF {
  toggleFullScreen: () => void;
  initialFullScreenState: boolean;
}

export const HamburgerMenu: React.FunctionComponent<HamburgerMenuIF> = ({
  toggleFullScreen,
  initialFullScreenState,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(initialFullScreenState);
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);
  const trainingPromptDisplay: TrainingPromptDisplay = useFluxPath(
    sqHomeScreenStore,
    () => sqHomeScreenStore.trainingPromptDisplay,
  );
  const showTrainingModal = trainingPromptDisplay === TrainingPromptDisplay.MODAL;
  const exitHandler = () => {
    if (
      !document.fullscreenElement &&
      !document['webkitIsFullScreen'] &&
      !document['mozFullScreen'] &&
      !document['msFullscreenElement']
    ) {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    };
  }, []);

  const { t } = useTranslation();
  const licenseStatus = useFluxPath(sqLicenseManagementStore, () => sqLicenseManagementStore.licenseStatus);
  const accessibleWithCurrentLicense = !_.includes(
    [LICENSE_STATUS.NONE, LICENSE_STATUS.UNKNOWN, LICENSE_STATUS.EXPIRED],
    licenseStatus,
  );

  const openApiReference = () => {
    // Construct a new URL to the apiReference page using, using the current protocol/host/port
    const swaggerUrl = `/api-docs/?baseUrl=${window.encodeURIComponent(`${APPSERVER_API_PREFIX}/swagger.json`)}`;
    window.open(swaggerUrl);
  };

  const cancelCurrentUserServerRequests = () =>
    cancelCurrentUserServerRequestsUtils()
      .then(() => {
        successToast({ messageKey: 'REQUEST_CANCELLATION.SUCCESS_USER' });
      })
      .catch((error) => {
        warnToast({ messageKey: 'REQUEST_CANCELLATION.FAILURE' });
        return error;
      });

  const cancelAllServerRequests = () =>
    cancelAllServerRequestsUtils()
      .then(emitAllServerRequestsCanceled)
      .then(() => {
        successToast({ messageKey: 'REQUEST_CANCELLATION.SUCCESS_ALL' });
      })
      .catch((error) => {
        warnToast({ messageKey: 'REQUEST_CANCELLATION.FAILURE' });
        return error;
      });

  const openAdminTools = () => {
    window.open('/administration', 'SeeqAdmin');
  };

  const fullScreenAction = () => {
    toggleFullScreen();
    setIsFullScreen(!isFullScreen);
  };

  const generateDropdownEntry = ({
    iconClass = '',
    translationKey = '',
    action = _.noop,
    divider = false,
    testId = '',
  }) => {
    return {
      key: `dropdown_${translationKey}`,
      icon: iconClass,
      containerExtraClassNames: 'sq-force-text-gray forceFont',
      labelTestId: `moreActions_${translationKey}`,
      iconTestId: `moreActionsIcon_${translationKey}`,
      onClick: action,
      label: t(translationKey),
      hasDivider: divider,
      testId,
    };
  };

  const actions = _.compact([
    {
      iconClass: isFullScreen
        ? 'fa-arrow-down-left-and-arrow-up-right-to-center'
        : 'fa-arrow-up-right-and-arrow-down-left-from-center',
      action: fullScreenAction,
      translationKey: isFullScreen ? 'RESTORE' : 'FULLSCREEN',
      display: true,
      testId: 'fullScreen',
      divider: true,
    },
    {
      iconClass: 'fa-question-circle',
      translationKey: 'SUPPORT_REQUEST.GET_HELP',
      action: () => setShowModal(SupportRequestModal, true),
      display: accessibleWithCurrentLicense,
      testId: 'supportRequest',
      divider: !releaseNotesEnabled(),
    },
    releaseNotesEnabled()
      ? {
          href: '#',
          iconClass: 'fa-memo-circle-info',
          translationKey: 'RELEASE_NOTES.TITLE',
          action: () => {
            setShowReleaseNotes(true);
            doTrack('ReleaseNotes', 'open');
          },
          display: accessibleWithCurrentLicense,
          testId: 'releaseNotes',
          divider: true,
        }
      : null,
    {
      iconClass: 'fc-external-link',
      translationKey: 'HOME_SCREEN.TRAINING.GET_STARTED',
      action: () => setTrainingPromptDisplay(TrainingPromptDisplay.MODAL),
      display: true,
      testId: 'getStarted',
    },
    {
      iconClass: 'fc-skill-explorer',
      translationKey: 'HOME_SCREEN.SKILL_EXPLORER',
      action: () => window.open('https://skill-explorer.seeq.com/'),
      display: true,
      testId: 'skillExplorer',
    },
    {
      iconClass: 'fc-training',
      translationKey: 'HOME_SCREEN.TRAINING_CERTIFICATION',
      action: () => {
        window.open('https://telemetry.seeq.com/training');
      },
      display: true,
      testId: 'trainingCertification',
    },
    {
      iconClass: 'fc-support',
      translationKey: 'HOME_SCREEN.OFFICE_HOURS',
      action: () => {
        window.open('https://telemetry.seeq.com/office-hours');
      },
      display: true,
      testId: 'officeHours',
      divider: true,
    },
    {
      iconClass: 'fa-code',
      translationKey: 'API_REFERENCE',
      action: openApiReference,
      display: accessibleWithCurrentLicense,
      testId: 'apiReference',
    },
    {
      iconClass: 'fa-lock',
      translationKey: 'ADMINISTRATION',
      action: openAdminTools,
      display: showAdministrationMenuItem() && accessibleWithCurrentLicense,
      testId: 'administration',
    },
    {
      iconClass: 'fa-file-zipper',
      translationKey: 'LICENSE.LICENSE_MANAGEMENT',
      display: isAdmin(),
      testId: 'licenseManagement',
      action: () => window.open('/license'),
    },
    {
      iconClass: 'fa-file-lines',
      translationKey: 'LOGS.VIEW_LOGS',
      display: accessibleWithCurrentLicense,
      testId: 'viewLogs',
      action: () => window.open('/logs'),
    },
    {
      iconClass: 'fas fa-th-list',
      translationKey: 'AUDIT_TRAIL.VIEW_ENTRIES',
      display: canReadAuditTrail(),
      testId: 'viewEntries',
      action: () => window.open('/auditTrail'),
      divider: true,
    },
    {
      iconClass: 'fa-user-xmark',
      translationKey: 'REQUEST_CANCELLATION.CANCEL_USER',
      action: cancelCurrentUserServerRequests,
      display: true,
      testId: 'requestCancellationUser',
    },
    {
      iconClass: 'fa-ban',
      translationKey: 'REQUEST_CANCELLATION.CANCEL_ALL',
      action: cancelAllServerRequests,
      display: hasAnalyticsAdminCapability(),
      testId: 'requestCancellationAll',
    },
  ]);

  return (
    <>
      <ButtonWithDropdown
        id="hamburger-menu"
        keepFocusInsideDropdown={false}
        triggerIcon={
          <Icon
            icon="fa-bars"
            extraClassNames="fa-xlg sq-navbar-default flexNoGrowNoShrink pr12"
            type="white"
            testId="hamburgerMenu"
            id="options"
          />
        }
        dropdownItems={_.filter(actions, (action) => action.display).map((action) => generateDropdownEntry(action))}
      />
      {showReleaseNotes && <ReleaseNotesModal onClose={() => setShowReleaseNotes(false)} />}
      {showTrainingModal && (
        <TrainingPromptModal onClose={() => setTrainingPromptDisplay(TrainingPromptDisplay.NONE)} />
      )}
    </>
  );
};
