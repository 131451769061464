import { excludedColumns } from '@/vantage/evidence';
import { BaseColumns, TableColumns } from '@/vantage/types/TableConfig.types';
import { VantageNames } from '@/vantage/constants';
import _ from 'lodash';
import { ColumnDefinitionOutputV1 } from '@/sdk/model/ColumnDefinitionOutputV1';
import { ExtensionSchema } from '@/vantage/types/Extension.types';

export const getTableColumns = (
  columnDefinitions: ColumnDefinitionOutputV1[] | undefined = [],
  extensions: ExtensionSchema[] | undefined = [],
): TableColumns => {
  const base = {
    ...Object.fromEntries(
      columnDefinitions
        .filter((column) => !excludedColumns.includes(column.columnName))
        .map((column) => [
          column.columnName,
          {
            meta: { type: column.columnType },
          },
        ]),
    ),
    [VantageNames.Columns.ConditionID]: {
      meta: { type: 'condition_id' },
    },
    [VantageNames.Columns.Condition]: {
      meta: { type: 'reference', func: ['group'], referenced: VantageNames.Columns.ConditionID },
    },
    [VantageNames.Columns.DatumID]: {
      meta: { type: 'text' },
    },
    'Time Created': { meta: { type: 'datetime' }, headerName: 'VANTAGE.EVIDENCE_TABLE.COLUMNS.TIME_CREATED' },
    'Time Updated': { meta: { type: 'datetime' }, headerName: 'VANTAGE.EVIDENCE_TABLE.COLUMNS.TIME_UPDATED' },
    [VantageNames.Columns.CapsuleID]: {
      meta: { type: 'text' },
    },
    [VantageNames.Columns.Suppressed]: {
      meta: {
        aggFunc: 'Aggregated',
        colDefDefaultValue: false,
        colDefDefaultFilter: ['false'],
        colDefMinWidth: 110,
        colDefMaxWidth: 150,
        colDefName: VantageNames.Columns.Suppressed,
        colDefType: 'boolean',
        onClickAction: 'toggleState',
        type: 'structured',
        defaultValue: false,
        discreteStates: [
          {
            testId: 'Not Suppressed',
            labelTranslationKey: 'VANTAGE.EXTENSIONS.SUPPRESSION.NOT_SUPPRESSED',
            value: false,
            icon: 'svgpath:M 0 0 L 1.583 0 L 17.417 0 L 19 0 L 19 2.375 L 17.417 2.375 L 17.417 4.75 L 17.417 5.244 L 16.952 5.592 L 11.741 9.5 L 16.952 13.411 L 17.417 13.76 L 17.417 14.25 L 17.417 16.625 L 19 16.625 L 19 19 L 17.417 19 L 1.583 19 L 0 19 L 0 16.625 L 1.583 16.625 L 1.583 14.25 L 1.583 13.756 L 2.048 13.408 L 7.259 9.5 L 2.048 5.589 L 1.583 5.244 L 1.583 4.75 L 1.583 2.375 L 0 2.375 L 0 0 Z M 4.75 2.375 L 4.75 4.256 L 5.408 4.75 L 13.592 4.75 L 14.25 4.256 L 14.25 2.375 L 4.75 2.375 Z M 5.408 14.25 L 13.592 14.25 L 9.5 11.181 L 5.408 14.25 Z',
            iconFill: 'white',
          },
          {
            testId: 'Suppressed',
            labelTranslationKey: 'VANTAGE.EXTENSIONS.SUPPRESSION.SUPPRESSED',
            value: true,
            icon: 'svgpath:M 0 0 L 1.583 0 L 17.417 0 L 19 0 L 19 2.375 L 17.417 2.375 L 17.417 4.75 L 17.417 5.244 L 16.952 5.592 L 11.741 9.5 L 16.952 13.411 L 17.417 13.76 L 17.417 14.25 L 17.417 16.625 L 19 16.625 L 19 19 L 17.417 19 L 1.583 19 L 0 19 L 0 16.625 L 1.583 16.625 L 1.583 14.25 L 1.583 13.756 L 2.048 13.408 L 7.259 9.5 L 2.048 5.589 L 1.583 5.244 L 1.583 4.75 L 1.583 2.375 L 0 2.375 L 0 0 Z M 4.75 2.375 L 4.75 4.256 L 5.408 4.75 L 13.592 4.75 L 14.25 4.256 L 14.25 2.375 L 4.75 2.375 Z M 5.408 14.25 L 13.592 14.25 L 9.5 11.181 L 5.408 14.25 Z',
            iconFill: 'purple',
          },
        ],
      },
    },
  } as BaseColumns;

  const tableColumns: TableColumns = {
    base,
    extensions: _.chain(extensions)
      .filter((ext) => (ext.contextCharacteristics?.length ?? -1) > 0 && (ext.uiConfig?.columns?.length ?? -1) > 0)
      .flatMap((ext) =>
        (ext.uiConfig?.columns ?? [])
          .filter((column) => column.colDefName && column.colDefType && !base[column.colDefName])
          .map((column) => [
            column.colDefName,
            {
              meta: {
                ...column,
                kindIdentifierPairs: Object.fromEntries(
                  ext.contextCharacteristics?.map((c) => [c.kind, c.proxyIdentifier || ext.name]) ?? [],
                ),
                kindReducerPairs: Object.fromEntries(ext.contextCharacteristics?.map((c) => [c.kind, c.reducer]) ?? []),
              },
            },
          ]),
      )
      .uniqBy(([name]) => name)
      .fromPairs()
      .value(),
  };

  return tableColumns;
};
