import { PersistenceLevel, Store } from '@/core/flux.service';
import {
  defaultLayoutDefinition,
  ImpactReportCategory,
  ImpactReportDashboardContent,
} from '@/impactReports/impactReport.types';
import _ from 'lodash';

interface ImpactReportState {
  tableId: string | undefined;
  forceTableReload: boolean;
  categories: ImpactReportCategory[] | undefined;
  startDate: Date | null;
  endDate: Date | null;
  layoutDefinition: ImpactReportDashboardContent[];
  aggregationColumn: string | undefined;
  biggestImpact: number;
  useCaseCount: number;
  overallImpact: number;
  filteredImpact: number;
  displayEditModal: boolean;
}

export class ImpactReportStore extends Store {
  static readonly storeName = 'sqImpactReportStore';
  persistenceLevel: PersistenceLevel = 'WORKBENCH';

  get tableId(): string | undefined {
    return this.state.get('tableId');
  }

  get startDate(): Date | undefined {
    return this.state.get('startDate');
  }

  get endDate(): Date | undefined {
    return this.state.get('endDate');
  }

  get categories(): ImpactReportCategory[] | undefined {
    return this.state.get('categories');
  }

  get layoutDefinition(): ImpactReportDashboardContent[] {
    return this.state.get('layoutDefinition');
  }

  get forceTableReload(): boolean {
    return this.state.get('forceTableReload');
  }

  get aggregationColumn(): string | undefined {
    return this.state.get('aggregationColumn');
  }

  get biggestImpact(): number {
    return this.state.get('biggestImpact');
  }

  get useCaseCount(): number {
    return this.state.get('useCaseCount');
  }

  get overallImpact(): number {
    return this.state.get('overallImpact');
  }

  get filteredImpact(): number {
    return this.state.get('filteredImpact');
  }

  get displayEditModal(): boolean {
    return this.state.get('displayEditModal');
  }

  initialize() {
    this.state = this.immutable({
      tableId: undefined,
      categories: undefined,
      startDate: null,
      endDate: null,
      forceTableReload: false,
      layoutDefinition: defaultLayoutDefinition,
      aggregationColumn: undefined,
      biggestImpact: 0,
      useCaseCount: 0,
      overallImpact: 0,
      filteredImpact: 0,
      displayEditModal: false,
    });
  }

  dehydrate() {
    return _.pick(this.state.serialize(), ['layoutDefinition']);
  }

  rehydrate(dehydratedState: ImpactReportState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    IMPACT_REPORT_SET_TABLE_ID: (payload: { tableId: string }) => {
      this.state.set('tableId', payload.tableId);
    },
    IMPACT_REPORT_SET_CATEGORIES: (payload: { categories: ImpactReportCategory[] }) => {
      this.state.set('categories', payload.categories);
    },
    IMPACT_REPORT_SET_START_DATE: (payload: { startDate: Date }) => {
      this.state.set('startDate', payload.startDate);
    },
    IMPACT_REPORT_SET_END_DATE: (payload: { endDate: Date }) => {
      this.state.set('endDate', payload.endDate);
    },
    IMPACT_REPORT_SET_LAYOUT_DEFINITION: (payload: { layoutDefinition: ImpactReportDashboardContent[] }) => {
      this.state.set('layoutDefinition', payload.layoutDefinition);
    },
    IMPACT_REPORT__REMOVE_CONTENT: (payload: { i: string }) => {
      this.state.set(
        'layoutDefinition',
        this.layoutDefinition.filter((content: { i: string }) => content.i !== payload.i),
      );
    },
    IMPACT_REPORT_SET_FORCE_TABLE_RELOAD: (payload: { forceTableReload: boolean }) => {
      this.state.set('forceTableReload', payload.forceTableReload);
    },
    IMPACT_REPORT_SET_AGGREGATION_COLUMN: (payload: { aggregationColumn: string }) => {
      this.state.set('aggregationColumn', payload.aggregationColumn);
    },
    IMPACT_REPORT_SET_BIGGEST_IMPACT: (payload: { biggestImpact: number }) => {
      this.state.set('biggestImpact', payload.biggestImpact);
    },
    IMPACT_REPORT_SET_USE_CASE_COUNT: (payload: { useCaseCount: number }) => {
      this.state.set('useCaseCount', payload.useCaseCount);
    },
    IMPACT_REPORT_SET_OVERALL_IMPACT: (payload: { overallImpact: number }) => {
      this.state.set('overallImpact', payload.overallImpact);
    },
    IMPACT_REPORT_SET_FILTERED_IMPACT: (payload: { filteredImpact: number }) => {
      this.state.set('filteredImpact', payload.filteredImpact);
    },
    IMPACT_REPORT_SET_DISPLAY_EDIT_MODAL: (payload: { displayEditModal: boolean }) => {
      this.state.set('displayEditModal', payload.displayEditModal);
    },
  };
}
