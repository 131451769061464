// @ts-strict-ignore
import _ from 'lodash';
import { formatDuration, getCapsuleFormula, secondsToMillis } from '@/datetime/dateTime.utilities';
import {
  BuildAdditionalCapsuleTableFormulaCallback,
  BuildConditionFormulaCallback,
  BuildStatFormulaCallback,
  CapsuleFormulaTableRow,
  FetchParamsForColumn,
  PropertyColumn as FormulaPropertyColumn,
  StatColumn,
} from '@/utilities/formula.constants';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { STRING_UOM } from '@/main/app.constants';
import {
  base64guid,
  equalsIgnoreCase,
  getCapsuleDuration,
  getShortIdentifier,
  isStringSeries as isStringSeriesUtil,
} from '@/utilities/utilities';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import {
  COLUMNS_AND_STATS,
  ENUM_REGEX,
  ITEM_TYPES,
  PropertyColumn,
  StatisticColumn,
  TREND_CONDITION_STATS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
} from '@/trendData/trendData.constants';
import { infoToast } from '@/utilities/toast.utilities';
import {
  formatMetricValue,
  isPropertyColumnType,
  isPropertyOrStatColumn,
  isPropertyOrStatOrMetricColumn,
} from '@/utilities/tableBuilderHelper.utilities';
import i18next from 'i18next';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import {
  AgGridAggregationFunction,
  COLUMN_PREFIX,
  CONDITION_EXTRA_COLUMNS,
  CONDITION_TABLE_DEFAULT_COLUMNS,
  ITEM_UOM,
  MAX_CONDITION_TABLE_CAPSULES,
  MetricPropertyColumn,
  NULL_PLACEHOLDER,
  PREDEFINED_COLUMN_INDEX,
  SIMPLE_TABLE_DEFAULT_COLUMNS,
  SIMPLE_TABLE_ID_COLUMN,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
  TRANSPOSE_HEADER_COLUMNS,
  withDefaultFormatting,
} from '@/tableBuilder/tableBuilder.constants';
import {
  sqDurationStore,
  sqTrendMetricStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { isItemRedacted } from '@/utilities/redaction.utilities';
import {
  getBuildAdditionalFormula,
  getBuildStatFormulaFunctionCallback,
  getStringPropertyFetchParams,
  getStringStatFetchParams,
} from '@/utilities/formula.utilities';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { createSelector } from 'reselect';
import {
  ColumnOrRow,
  ColumnOrRowWithDefinitions,
  ColumnPosition,
  ColumnToThresholdsCondition,
  ColumnToThresholdsSimple,
  ColumnWithIndex,
  ConditionTableCapsule,
  ConditionTableColumnsAndRows,
  ConditionTableData,
  ConditionTableHeader,
  ConditionTableValue,
  ItemColumnsMap,
  OtherColumns,
  SimpleTableResultTable,
  SimpleTableRow,
  TableBuilderHeaders,
  TableColumn,
} from '@/tableBuilder/tableBuilder.types';
import { deepEqualSelector } from '@/utilities/selector.utilities';
import { tableBuilder } from '@/tableBuilder/tableBuilder.utilities';
import { defaultSettings } from './tableViewer/ChartSettings.molecule';
import type { ChartModel } from '@ag-grid-community/core';
import { isBackendRowsLimitError } from '@/trendData/trend.utilities';
import { isThresholdMetric, Item, Signal } from '@/utilities/items.types';

const GENERIC_ITEM_IDENTIFIER = 'series';

export class TableBuilderStore extends Store {
  static readonly storeName = 'sqTableBuilderStore';
  persistenceLevel: PersistenceLevel = 'WORKSHEET';

  /**
   * Initializes the store by setting default values to the stored state
   */
  initialize() {
    this.state = this.immutable({
      mode: TableBuilderMode.Simple,
      headers: {
        [TableBuilderMode.Condition]: {
          type: TableBuilderHeaderType.StartEnd,
          format: 'lll',
        },
        [TableBuilderMode.Simple]: {
          type: TableBuilderHeaderType.StartEnd,
          format: 'lll',
        },
      },
      columns: {
        [TableBuilderMode.Condition]: CONDITION_TABLE_DEFAULT_COLUMNS,
        [TableBuilderMode.Simple]: SIMPLE_TABLE_DEFAULT_COLUMNS,
      },
      otherColumns: {
        [TableBuilderMode.Condition]: {},
        [TableBuilderMode.Simple]: {},
      },
      tableData: {
        [TableBuilderMode.Condition]: { headers: [], capsules: [] },
        [TableBuilderMode.Simple]: [],
      },
      autoGroupColumn: {
        [TableBuilderMode.Condition]: undefined,
        [TableBuilderMode.Simple]: undefined,
      },
      rowGroupPaths: {
        [TableBuilderMode.Condition]: [],
        [TableBuilderMode.Simple]: [],
      },
      isTransposed: {
        [TableBuilderMode.Condition]: true,
        [TableBuilderMode.Simple]: false,
      },
      assetId: {
        [TableBuilderMode.Condition]: undefined,
        [TableBuilderMode.Simple]: undefined,
      },
      isHomogenizeUnits: {
        [TableBuilderMode.Condition]: false,
        [TableBuilderMode.Simple]: false,
      },
      distinctStringValueMap: {
        [TableBuilderMode.Condition]: {},
        [TableBuilderMode.Simple]: {},
      },
      columnIdToAutoHeight: {
        [TableBuilderMode.Condition]: {},
        [TableBuilderMode.Simple]: {},
      },
      hasMoreData: false,
      fetchFailedMessage: undefined,
      isMigrating: false,
      clipboardStyle: {},
      chartView: {
        enabled: false,
        settings: defaultSettings,
        conditionSettings: undefined,
        conditionEnabled: false,
      },
      areAllRowsExpanded: undefined,
      useSignalColorsInChart: true,
    });
  }

  get hasMoreData(): boolean {
    return this.state.get('hasMoreData');
  }

  /**
   * Returns the table builder mode (Condition or Simple)
   */
  get mode(): TableBuilderMode {
    return this.state.get('mode');
  }

  /**
   * The header settings for the table
   */
  get headers(): TableBuilderHeaders {
    return this.state.get('headers', this.state.get('mode'));
  }

  /**
   * The columns for a table, with both their settings and custom text
   */
  get columns() {
    return this.getColumnsWithDefinition({ columns: this.getColumns(), autoGroupColumn: this.autoGroupColumn });
  }

  get conditionTableColumns() {
    return this.getConditionTableColumns({
      columns: this.columns,
      workingSetOfItems: this.getTableItemsProcess(),
    });
  }

  get otherColumns(): OtherColumns {
    return this.state.get('otherColumns');
  }

  /**
   * Whether or not we always want to set the given column to calculate it's row height automatically. Necessary for
   * when a column includes newlines
   */
  get columnIdToAutoHeight(): Record<string, boolean> {
    return this.state.get('columnIdToAutoHeight', this.state.get('mode'));
  }

  /**
   * Custom item/capsule properties used in the table.
   */
  get propertyColumns(): PropertyColumn[] {
    return this.getPropertyColumns(this.getColumns());
  }

  /**
   * @returns the value of the headerOverridden flag for the specified column
   */
  getOverriddenHeaderColumn: (columns: any[]) => any = createSelector(
    (columns: any[]) => columns,
    (columns) => _.find(columns, 'headerOverridden'),
  );

  get overriddenHeaderColumn() {
    return this.getOverriddenHeaderColumn(this.getColumns());
  }

  /** Changes when filter or sort or column order changes */
  get columnsWithIndex(): ColumnWithIndex[] {
    return this.getColumnsWithIndex({
      columns: this.columns,
      conditionTableRows: this.conditionTableColumns.rows,
      mode: this.mode,
    });
  }

  get rawConditionTableData(): ConditionTableData {
    return this.state.get('tableData', TableBuilderMode.Condition);
  }

  /**
   * Data generated for the condition table display. This is accessed and set separately from simpleTableData
   * because the asynchronous nature of fetching table data means that there's a chance data could be written
   * in the wrong location if we get/set dependent only on the TableBuilderMode. See CRAB-22970.
   */
  get conditionTableData(): ConditionTableData {
    return this.getConditionTableData({
      columnsWithIndex: this.columnsWithIndex,
      rawTableData: this.rawConditionTableData,
      isTransposed: this.isTransposed,
    });
  }

  get rawSimpleTableData(): SimpleTableRow[] {
    return this.state.get('tableData', TableBuilderMode.Simple);
  }

  /**
   * Data generated for the simple table display. This is accessed and set separately from conditionTableData
   * because the asynchronous nature of fetching table data means that there's a chance data could be written
   * in the wrong location if we get/set dependent only on the TableBuilderMode. See CRAB-22970.
   */
  get simpleTableData(): SimpleTableRow[] {
    return this.getSimpleTableData({
      columnsWithIndex: this.columnsWithIndex,
      rawTableData: this.rawSimpleTableData,
    });
  }

  get isTransposed() {
    return this.state.get('isTransposed', this.state.get('mode'));
  }

  get assetId(): string | undefined {
    return this.getAssetId();
  }

  get isHomogenizeUnits() {
    return this.getIsHomogenizeUnits();
  }

  get isMigrating() {
    return this.state.get('isMigrating');
  }

  get isTableStriped() {
    return this.state.get('isTableStriped', this.state.get('mode'));
  }

  get useSignalColorsInChart() {
    return this.state.get('useSignalColorsInChart');
  }

  get fetchFailedMessage() {
    return this.state.get('fetchFailedMessage');
  }

  get distinctStringValueMap(): {
    [mode: string]: { [columnKey: string]: string[] };
  } {
    return this.state.get('distinctStringValueMap');
  }

  /**
   * See if the chartView is enabled or not
   */
  get showChartView(): boolean {
    return this.state.get('chartView', 'enabled');
  }

  /**
   * See if the chartView is enabled or not
   */
  get showConditionChartView(): boolean {
    return this.state.get('chartView', 'conditionEnabled');
  }

  /**
   * Get the value for expand all or collapse all. undefiend means noop.
   */
  get areAllRowsExpanded(): boolean {
    return this.state.get('areAllRowsExpanded');
  }

  /**
   * Get the chart view settings
   */
  get chartViewSettings() {
    return this.state.get('chartView', 'settings');
  }

  /**
   * Get the condition table chart view settings
   */
  get chartViewConditionSettings() {
    return this.state.get('chartView', 'conditionSettings');
  }

  /**
   * Returns the Seeq-stored state for the grouping column in ag-grid
   */
  get autoGroupColumn(): Record<string, never> | undefined {
    return this.state.get('autoGroupColumn', this.state.get('mode'));
  }

  get rowGroupPaths(): string[] {
    return this.state.get('rowGroupPaths', this.state.get('mode'));
  }

  /**
   * @returns true if simple table mode is active and false is condition table mode is active
   */
  isSimpleMode(): boolean {
    return this.getIsSimpleMode();
  }

  /**
   * @returns the items to be displayed in the table filtered based on simple/condition mode.
   */
  getTableItems(): any[] {
    return this.getTableItemsProcess();
  }

  /**
   * Builds the necessary information to fetch the simple table.
   *
   * @param itemsOfTheSameType - Items of the same type that are used to construct and organize needed information that
   * will be used to
   * compute the table.
   * @returns An object containing the formula, parameters and the positions of each column which can be used in
   * conjunction with #setSimpleTableData()
   */
  getSimpleTableFetchParams(itemsOfTheSameType: Item[]) {
    const item = _.first(itemsOfTheSameType);
    const items = this.getTableItemsProcess();
    if (items.length === 0 || !item) {
      return {
        formula: '',
        parameters: {},
        root: undefined,
        columnPositions: [],
      };
    }

    const columnPositions: ColumnPosition[] = [];
    const columnFormulas: string[] = [];
    const isSignal = item.itemType === ITEM_TYPES.SERIES;
    const isStringSeries = isStringSeriesUtil(item as Signal);
    const isCondition = item.itemType === ITEM_TYPES.CONDITION;
    const statColumns = [];
    const itemIdentifier = GENERIC_ITEM_IDENTIFIER;
    const isMetric = item.itemType === ITEM_TYPES.METRIC;

    const isRunAcrossAssets = !!this.getAssetId();
    const isHomogenizeUnits = this.getIsHomogenizeUnits();
    let resultIndex = 2; // First two columns are start and end time
    let identifierIndex = 0;
    const parameters = {};

    const columns = _.chain(this.columns)
      .reject({
        type: TableBuilderColumnType.Text,
      })
      .thru((columns) => {
        if (!_.some(columns, (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key)) {
          columns.push(COLUMNS_AND_STATS.valueUnitOfMeasure);
        }
        if (isMetric) {
          columns.push({
            key: SeeqNames.Properties.MeasuredItemId,
            propertyName: SeeqNames.Properties.MeasuredItemId,
            style: 'string',
          });
          columns.push({
            key: SeeqNames.Properties.AggregationFunction,
            propertyName: SeeqNames.Properties.AggregationFunction,
            style: 'string',
          });
        }
        return columns;
      })
      .value() as any[];

    //Item ID is arbitrary and will be replaced on the backend with the specific ID of the item. Sending empty guid
    // won't work since parameters are validated on the backend.
    parameters[itemIdentifier] = item.id;
    // All items need a column with their ID so that there is a unique identifier when run across assets
    const idIdentifier = `series_id`;
    parameters[idIdentifier] = `$series.property('${SeeqNames.Properties.Id}')`;
    columnPositions.push({
      key: SIMPLE_TABLE_ID_COLUMN,
      index: resultIndex++,
    });
    columnFormulas.push(`.addColumn('${itemIdentifier}.${COLUMN_PREFIX}ID', $${idIdentifier})`);
    // The column name for a metric value is the itemId, but the name of a metric column that
    // is associated with a stat column is: <measuredItemShortId>.<metricId>
    const buildConvertUnitsFormulaFragment = (itemId: string, columnName?: string): string => {
      let convertUnits = '';
      if (isRunAcrossAssets && !isStringSeries) {
        let uom: string;
        if (isHomogenizeUnits) {
          const fixedMetricUOMIdentifier = getShortIdentifier(identifierIndex++);
          parameters[fixedMetricUOMIdentifier] = `$series.property('${SeeqNames.Properties.SwapSourceUom}')`;
          uom = `$${fixedMetricUOMIdentifier}`;
        } else {
          // remove units when we run across assets and homogenize units is not possible
          uom = "''";
        }
        convertUnits = `.convertUnits('${columnName ?? itemId}', ${uom})`;
      }
      return convertUnits;
    };

    if (isMetric && !_.some(columns, (column) => column.key === COLUMNS_AND_STATS.metricValue.key)) {
      // Add metricValue to all metrics, even if the column isn't being displayed, so that the priority color can also be applied if the corresponding statistic and measured item are configured to show in the table.
      const convertUnits = buildConvertUnitsFormulaFragment(item.id);
      columnFormulas.push(`.addSimpleMetricColumn('${item.id}', $${itemIdentifier})${convertUnits}`);
      columnPositions.push({
        key: COLUMNS_AND_STATS.metricValue.key,
        index: resultIndex,
        metricId: item.id,
      });
      resultIndex += 2;
    }

    _.forEach(columns, (column) => {
      if (column.style === 'metric') {
        if (isThresholdMetric(item)) {
          const convertUnits = buildConvertUnitsFormulaFragment(item.id);
          columnFormulas.push(`.addSimpleMetricColumn('${item.id}', $${itemIdentifier})${convertUnits}`);
          columnPositions.push({
            key: column.key,
            index: resultIndex,
            metricId: item.id,
          });
          // Metrics add two columns, one for value, and one for color
          resultIndex += 2;
        }
      } else if (column.stat) {
        const isStatColumn =
          (isSignal &&
            _.some(TREND_SIGNAL_STATS, ['stat', column.stat]) &&
            (!isStringSeries || column.isStringCompatible)) ||
          (isCondition && _.some(TREND_CONDITION_STATS, ['stat', column.stat]));

        if (!isMetric && isStatColumn) {
          statColumns.push(column);
        }
      } else {
        let propertyFormula = this.getSimpleTablePropertyColumnFormula(column, item, itemIdentifier);
        if (
          isHomogenizeUnits &&
          isRunAcrossAssets &&
          !isStringSeries &&
          column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key
        ) {
          // If homogenizing units then we need to know the UOM of the unswapped (swapOut) item
          propertyFormula = propertyFormula.replace(SeeqNames.Properties.ValueUom, SeeqNames.Properties.SwapSourceUom);
        }
        const propertyIdentifier = getShortIdentifier(identifierIndex++);
        parameters[propertyIdentifier] = propertyFormula;
        columnFormulas.push(`.addColumn('${itemIdentifier}.${column.key}', $${propertyIdentifier})`);

        columnPositions.push({
          key: column.key,
          index: resultIndex++,
        });
      }
    });

    // Add all the statistic columns as a group for best backend performance
    if (statColumns.length > 0) {
      _.forEach(statColumns, (column) => {
        columnPositions.push({
          key: column.key,
          index: resultIndex++,
        });
      });
      const stats = _.chain(statColumns).map('stat').join(', ').value();
      let itemReference = `$${itemIdentifier}`;
      // convertUnits does not accept conditions as input. Not an issue because our current condition stats are
      // unitless.
      if (isSignal && isRunAcrossAssets && !isStringSeries) {
        if (isHomogenizeUnits) {
          const fixedItemUOMIdentifier = getShortIdentifier(identifierIndex++);
          parameters[fixedItemUOMIdentifier] = `$series.property('${SeeqNames.Properties.SwapSourceUom}')`;
          itemReference = `$${itemIdentifier}.convertUnits($${fixedItemUOMIdentifier})`;
        } else {
          itemReference = `$${itemIdentifier}.convertUnits('')`;
        }
      }
      columnFormulas.push(`.addStatColumn('${itemIdentifier}', ${itemReference}, ${stats})`);
    }

    let originalItemIdFormula = '';
    if (isRunAcrossAssets) {
      const fixedItemUOMIdentifier = getShortIdentifier(identifierIndex++);
      originalItemIdFormula = `.addColumn('${SeeqNames.Properties.SwapSourceId}', $${fixedItemUOMIdentifier})`;
      parameters[fixedItemUOMIdentifier] = `$series.property('${SeeqNames.Properties.SwapSourceId}')`;
    }

    const viewCapsule = getCapsuleFormula(sqDurationStore.displayRange);
    const columnsFormula = columnFormulas.join('');
    const formula = `group(${viewCapsule}).toTable('simple')${columnsFormula}${originalItemIdFormula}`;
    return {
      formula,
      parameters,
      root: this.getAssetId(),
      columnPositions,
    };
  }

  /**
   * Builds the variables necessary for fetching the condition table.
   *
   * @returns An object containing the item necessary for fetch and used in conjunction with #setConditionTableData
   */
  getConditionTableFetchParams() {
    const items = this.getTableItemsProcess() as any[];
    const assetId = this.getAssetId();
    const isRunAcrossAssets = !!this.getAssetId();
    const isHomogenizeUnits = this.getIsHomogenizeUnits();
    const allColumns = this.columns;
    const metrics: any[] = items.filter((item) => item.itemType === ITEM_TYPES.METRIC);
    const itemColumnsMap = tableBuilder.metricsToItemColumnsMap(metrics);

    const itemColumns: MetricPropertyColumn[] = _.chain(itemColumnsMap).values().flatMap(_.values).value();

    const propertyColumns = (itemColumns as any[])
      .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.startTime.key }) || COLUMNS_AND_STATS.startTime)
      .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime)
      .concat(this.propertyColumns)
      .concat(_.filter(allColumns, 'propertyExpression'))
      .map((column) => _.omit(column, 'filter'));

    const statColumns = _.sortBy(
      tableBuilder.getStatColumns(this.columns, sqTrendSeriesStore.findItem),
      'signalId',
    ).map((column) => _.omit(column, 'filter') as StatColumn);

    const conditionPropertyColumns = _.filter(
      allColumns,
      (column) => column.type === TableBuilderColumnType.Property || column.propertyExpression,
    );
    const buildConditionFormula: BuildConditionFormulaCallback = this.getBuildConditionFormulaFunction(
      items,
      itemColumnsMap,
      conditionPropertyColumns,
      isRunAcrossAssets,
      statColumns,
    );

    const buildAdditionalFormula: BuildAdditionalCapsuleTableFormulaCallback = getBuildAdditionalFormula(
      propertyColumns,
      statColumns,
      false,
      [],
      isRunAcrossAssets,
      _.chain(metrics).reject(isStringSeriesUtil).map('id').value(),
      isHomogenizeUnits,
    );

    const buildStatFormula: BuildStatFormulaCallback = this.getBuildStatFormulaFunction(statColumns);

    const headers = this.state.get('headers', TableBuilderMode.Condition);
    let customPropertyName: string;
    if (headers.type === TableBuilderHeaderType.CapsuleProperty) {
      customPropertyName = headers.property;
      // Account for properties that are already in the table but may have different names/keys
      // (e.g. the property Start uses the key startTime)
      const customPropertyColumn = _.find(propertyColumns, (column) =>
        equalsIgnoreCase(column.propertyName, customPropertyName),
      );
      if (!customPropertyColumn) {
        propertyColumns.push({
          key: customPropertyName,
          invalidsFirst: true,
          propertyName: customPropertyName,
        });
      } else {
        customPropertyName = customPropertyColumn.key;
      }
    }

    const ids = _.map(items, 'id');

    return {
      ids,
      assetId,
      propertyColumns,
      statColumns,
      customPropertyName,
      buildAdditionalFormula,
      itemColumnsMap,
      buildConditionFormula,
      buildStatFormula,
    };
  }

  /**
   * Gets the necessary information to fetch distinct string values that appear in different Simple Table columns.
   * Each set of fetch params returned corresponds to one column in the displayed Simple Table.  The fetch params
   * correspond to a table that has only the necessary information to get the column's string values.
   *
   * @returns obj.fetchParamsList: array of fetch param objects, each with a table formula
   *          obj.columnKeysNamesList: array of {columnKey, columnNames} objects where columnKey is the key of
   *            the displayed frontend table, and columnNames contains the names of the corresponding columns in
   *            the computed table returned from the backend, since those columns are combined to form the
   *            displayed Simple Table.
   */
  getSimpleTableStringColumnsFetchParams(): {
    fetchParamsList: any[];
    columnKeysNamesList: string[];
  } {
    const items = this.getTableItemsProcess();
    const signals = _.filter(items, (item) => item.itemType === ITEM_TYPES.SERIES);
    const metrics = _.filter(items, (item) => item.itemType === ITEM_TYPES.METRIC);
    const root = this.getAssetId();
    const isRunAcrossAssets = !!root;
    const columns = _.reject(this.columns, {
      type: TableBuilderColumnType.Text,
    }) as any[];
    const columnKeysNamesList = [];
    const fetchParamsList = [];
    const hasOnlyStringSeries = !_.isEmpty(signals) && _.every(signals, (signal) => isStringSeriesUtil(signal));
    const hasOnlyStringMetrics = !_.isEmpty(metrics) && _.every(metrics, (metric) => isStringSeriesUtil(metric));
    const viewCapsule = getCapsuleFormula(sqDurationStore.displayRange);
    const baseFormula = `group(${viewCapsule}).toTable('simple')`;
    let identifierIndex = 0;

    _.forEach(columns, (column) => {
      if (hasOnlyStringSeries && column.stat && column.key === 'statistics.endValue') {
        let formula = baseFormula;
        const columnNames = [];
        const parameters = {};
        _.forEach(signals, (signal) => {
          const signalIdentifier = getShortIdentifier(identifierIndex++);
          parameters[signalIdentifier] = signal.id;
          columnNames.push(`${signalIdentifier} ${column.columnSuffix}`);
          formula = `${formula}.addStatColumn('${signalIdentifier}', $${signalIdentifier}, ${column.stat})`;
        });
        const additionalFormula = `.distinctColumnValues(${_.map(columnNames, (name) => `'${name}'`).join(', ')})`;
        formula = `${formula}${isRunAcrossAssets ? '' : additionalFormula}`;
        const reduceFormula = isRunAcrossAssets ? `$result${additionalFormula}` : undefined;
        fetchParamsList.push({
          formula,
          parameters,
          root,
          reduceFormula,
        });
        columnKeysNamesList.push({
          columnKey: column.key,
          columnNames,
        });
      } else if (hasOnlyStringMetrics && column.key === COLUMNS_AND_STATS.metricValue.key) {
        // metric value column for string metrics
        let formula = baseFormula;
        const columnNames = [];
        const parameters = {};
        _.forEach(metrics, (metric) => {
          const metricIdentifier = getShortIdentifier(identifierIndex++);
          parameters[metricIdentifier] = metric.id;
          columnNames.push(metric.id);
          formula = `${formula}.addSimpleMetricColumn('${metric.id}', $${metricIdentifier})`;
        });
        const additionalFormula = `.distinctColumnValues(${_.map(columnNames, (name) => `'${name}'`).join(', ')})`;
        formula = `${formula}${isRunAcrossAssets ? '' : additionalFormula}`;
        const reduceFormula = isRunAcrossAssets ? `$result${additionalFormula}` : undefined;
        fetchParamsList.push({
          formula,
          parameters,
          root,
          reduceFormula,
        });
        columnKeysNamesList.push({
          columnKey: column.key,
          columnNames,
        });
      } else if (_.includes(['string', 'assets', 'fullpath'], column.style) || column.isCustomProperty) {
        // property columns
        let formula = baseFormula;
        const columnNames = [];
        const parameters = {};
        _.forEach(items, (item) => {
          const itemIdentifier = getShortIdentifier(identifierIndex++);
          parameters[itemIdentifier] = item.id;
          const propertyFormula = this.getSimpleTablePropertyColumnFormula(column, item, itemIdentifier);
          const propertyIdentifier = getShortIdentifier(identifierIndex++);
          parameters[propertyIdentifier] = propertyFormula;
          columnNames.push(`${itemIdentifier}.${column.key}`);
          formula = `${formula}.addColumn('${itemIdentifier}.${column.key}', $${propertyIdentifier})`;
        });
        const additionalFormula = `.distinctColumnValues(${_.map(columnNames, (name) => `'${name}'`).join(', ')})`;
        formula = `${formula}${isRunAcrossAssets ? '' : additionalFormula}`;
        const reduceFormula = isRunAcrossAssets ? `$result${additionalFormula}` : undefined;
        fetchParamsList.push({
          formula,
          parameters,
          root,
          reduceFormula,
        });
        columnKeysNamesList.push({
          columnKey: column.key,
          columnNames,
        });
      }
    });

    return { fetchParamsList, columnKeysNamesList };
  }

  /**
   * Gets the necessary information to fetch distinct string values for a pick-list for column.
   *
   * @param columnKey - string column that is being filtered, to fetch string values for.
   */
  getConditionTableStringColumnFetchParams(columnKey: string): Promise<FetchParamsForColumn> {
    const items = this.getTableItemsProcess();
    const assetId = this.getAssetId();
    const isRunAcrossAssets = !!this.getAssetId();
    const allColumns = this.columns;
    const statColumns = tableBuilder.getStatColumns(this.columns, sqTrendSeriesStore.findItem);

    // String-valued metrics
    const metrics: any[] = _.filter(items, { itemType: ITEM_TYPES.METRIC });
    const metric = _.find(metrics, (metric) => metric.id === columnKey && isStringSeriesUtil(metric));
    const itemColumnsMap = tableBuilder.metricsToItemColumnsMap(metrics);
    if (metric) {
      const columns = itemColumnsMap[metric.id];
      const propertyColumns = (_.values(columns) as any[])
        .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.startTime.key }) || COLUMNS_AND_STATS.startTime)
        .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime);
      const buildAdditionalFormula = () => `.distinctColumnValues('${columns.value.key}')`;
      return Promise.resolve({
        columnKeyAndName: { columnName: columns.value.key, columnKey: metric.id },
        fetchParams: {
          ids: [metric.id],
          assetId,
          propertyColumns,
          statColumns: [],
          itemColumnsMap,
          buildAdditionalFormula: isRunAcrossAssets ? undefined : buildAdditionalFormula,
          reduceFormula: isRunAcrossAssets ? `$result${buildAdditionalFormula()}` : undefined,
          buildConditionFormula: this.getBuildConditionFormulaFunction(
            [metric],
            itemColumnsMap,
            [],
            isRunAcrossAssets,
            statColumns,
          ),
        },
      });
    }

    const statParams = getStringStatFetchParams(
      columnKey,
      statColumns,
      allColumns,
      _.map(items, 'id'),
      (statColumn) => this.getBuildStatFormulaFunction([statColumn]),
      assetId,
      this.getBuildConditionFormulaFunction(items, itemColumnsMap, [], isRunAcrossAssets, statColumns),
    );
    if (statParams) {
      return Promise.resolve(statParams);
    }

    const propertyColumn = _.find(
      this.propertyColumns,
      ({ propertyName }) => propertyName === columnKey,
    ) as PropertyColumn;
    const conditionPropertyColumn = _.find(
      allColumns,
      (column) =>
        columnKey === column.key && (column.type === TableBuilderColumnType.Property || column.propertyExpression),
    );
    if (conditionPropertyColumn) {
      const distinctColumnValuesFormula = `.distinctColumnValues('${conditionPropertyColumn.key}')`;
      return Promise.resolve({
        columnKeyAndName: {
          columnKey: conditionPropertyColumn.key,
          columnName: conditionPropertyColumn.key,
        },
        fetchParams: {
          ids: _.map(items, 'id'),
          assetId,
          // Prefer propertyColumn as it is the correct type except when it is a propertyExpression column
          propertyColumns: [propertyColumn ?? conditionPropertyColumn],
          statColumns: [],
          itemColumnsMap,
          buildAdditionalFormula: () => distinctColumnValuesFormula,
          reduceFormula: isRunAcrossAssets ? `$result${distinctColumnValuesFormula}` : undefined,
          buildConditionFormula: this.getBuildConditionFormulaFunction(
            items,
            itemColumnsMap,
            [conditionPropertyColumn],
            isRunAcrossAssets,
            statColumns,
          ),
        },
      });
    }

    if (propertyColumn) {
      return getStringPropertyFetchParams(
        _.filter(items, { itemType: ITEM_TYPES.CONDITION }),
        propertyColumn as FormulaPropertyColumn,
        assetId,
      );
    }

    // There are edge cases with metrics that can result in a property column that doesn't support
    // fetching the possible string values. Specifically, a metric built upon a condition that has a
    // Value property and the user adds the Value (original) column.
    return Promise.resolve({
      fetchParams: {},
      columnKeyAndName: { columnKey, columnName: columnKey },
    });
  }

  /**
   * Create a map between table columns and thresholds that correspond to metrics that are relevant to the column.
   * For stat columns, the map key is the aggregation function, and the value is a list of thresholds belonging
   * to any metrics that use that aggregation function.
   * For the metricValue column, the map key is 'metricValue', and the value is a list of all of the thresholds
   * for any relevant (i.e. simple) metrics.
   * This is used in table filtering to give the user the option of picking an existing threshold to use as a
   * filter.
   *
   * @returns the map between table columns and arrays of thresholds
   */
  getColumnToThresholdsForSimple(): ColumnToThresholdsSimple {
    const columns = this.columns;
    const columnStats = _.chain(columns)
      .filter((column: { stat?: string }) => !!column.stat)
      .map('stat')
      .value();
    const hasMetricValueColumn = _.some(columns, { key: 'metricValue' });
    const itemIds = _.map(this.getTableItems(), 'id');
    return (
      _.chain(
        getAllItems({
          workingSelection: false,
          itemTypes: [ITEM_TYPES.METRIC],
        }),
      )
        .reject((metric) => isItemRedacted(metric))
        .filter((metric) => metric.definition?.processType === ProcessTypeEnum.Simple)
        // filter out the conflictual metrics (multiple metrics with the same aggregation function for the same
        // item)
        .thru((metrics) =>
          _.reject(metrics, (metric) =>
            _.some(
              metrics,
              (otherMetric) =>
                metric.id !== otherMetric.id &&
                metric.definition.measuredItem.id === otherMetric.definition.measuredItem.id &&
                metric.definition.aggregationFunction === otherMetric.definition.aggregationFunction,
            ),
          ),
        )
        .transform((columnToThresholds, metric) => {
          const aggregationFunction = metric.definition.aggregationFunction;
          if (_.includes(columnStats, aggregationFunction) && _.includes(itemIds, metric.definition.measuredItem.id)) {
            columnToThresholds[aggregationFunction] = columnToThresholds[aggregationFunction] ?? [];
            const valueThresholds = _.reject(metric.definition.thresholds, (thresh) => _.isUndefined(thresh.value));
            columnToThresholds[aggregationFunction].push(...valueThresholds);
          }
          if (hasMetricValueColumn && _.includes(itemIds, metric.id)) {
            columnToThresholds.metricValue = columnToThresholds.metricValue ?? [];
            const valueThresholds = _.reject(metric.definition.thresholds, (thresh) => _.isUndefined(thresh.value));
            columnToThresholds.metricValue.push(...valueThresholds);
          }
        }, {} as ColumnToThresholdsSimple)
        .value()
    );
  }

  get columnToThresholdsForCondition(): ColumnToThresholdsCondition {
    return this.getColumnToThresholdsForCondition(this.getTableItemsProcess());
  }

  /**
   * Create a map between table columns and thresholds that correspond to metrics in the table. Used for
   * filtering the condition table.
   *
   * @returns the map between metrics and arrays of thresholds
   */
  getColumnToThresholdsForCondition: (items: any[]) => ColumnToThresholdsCondition = createSelector(
    (items) => _.filter(items, { itemType: ITEM_TYPES.METRIC }),
    (metrics) => {
      return _.transform(
        metrics,
        (result, metric) => {
          result[metric.id] = _.isEmpty(metric.definition?.thresholds) ? undefined : metric.definition.thresholds;
        },
        {} as ColumnToThresholdsCondition,
      );
    },
  );

  /**
   * Checks if the table contains a particular column
   * @param column - The column to check. One of COLUMNS_AND_STATS
   * @param [signalId] - The series if it is a statistic column for condition table
   * @returns true if the table has the column, false otherwise
   */
  isColumnEnabled(column: PropertyColumn | StatisticColumn, signalId: string = null) {
    return this.isColumnKeyEnabled(this.getColumnKeyFromSignalId(column, signalId));
  }

  /**
   * Gets the unique key for a column
   * @param column - The column to use. One of COLUMNS_AND_STATS
   * @param [signalId] - The series if it is a statistic column for condition table
   * @returns The unique key
   */
  getColumnKey(column: PropertyColumn | StatisticColumn | TableColumn, signalId: string = null) {
    return this.getColumnKeyFromSignalId(column, signalId);
  }

  /**
   * @returns true if simple table mode is active and false is condition table mode is active
   */
  getIsSimpleMode(): boolean {
    return this.state.get('mode') === TableBuilderMode.Simple;
  }

  /**
   * @returns the items to be displayed in the table filtered based on simple/condition mode.
   */
  getTableItemsProcess(): any[] {
    const simpleMode = this.getIsSimpleMode();
    const itemTypes = simpleMode
      ? [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.CONDITION, ITEM_TYPES.METRIC]
      : [ITEM_TYPES.METRIC, ITEM_TYPES.CONDITION];
    const { sortBy, sortAsc } = sqTrendStore.getPanelSort(TREND_PANELS.SERIES);

    return _.chain(
      getAllItems({
        workingSelection: true,
        itemTypes,
      }),
    )
      .reject((item) => isItemRedacted(item))
      .filter((item) => {
        if (item.definition?.processType) {
          return simpleMode
            ? item.definition.processType === ProcessTypeEnum.Simple
            : item.definition.processType !== ProcessTypeEnum.Simple;
        } else {
          return true;
        }
      })
      .orderBy([sortBy], [sortAsc ? 'asc' : 'desc'])
      .value();
  }

  /**
   * Checks if the table contains a particular column
   * @param key - Column key
   * @returns true if the table has the column, false otherwise
   */
  isColumnKeyEnabled(key: string): boolean {
    return this.getColumnIndex(key, true) > -1;
  }

  /**
   * Gets the unique key for a column
   * @param column - The column being toggled. One of COLUMNS_AND_STATS
   * @param [signalId] - The series if it is a statistic column for condition table
   */
  getColumnKeyFromSignalId(column: any, signalId: string = null) {
    return signalId ? `${column.statisticKey || column.key}_${signalId}` : column.key;
  }

  /**
   * Returns the columns for a table as persisted in the store (key, custom text, color). Other attributes like
   * accessor, transformResponse (present in TREND_COLUMNS) are excluded.
   */
  getColumns(): ColumnOrRow[] {
    return this.state.get('columns', this.state.get('mode'));
  }

  /**
   * Returns the columns for a table, with their definition if it is a statistic or property.
   */
  getColumnsWithDefinition: (state: {
    columns: ColumnOrRow[];
    autoGroupColumn: Record<string, never> | undefined;
  }) => ColumnOrRowWithDefinitions[] = createSelector(
    ({ columns }) => columns,
    ({ autoGroupColumn }) => autoGroupColumn,
    (columns, autoGroupColumn) =>
      columns
        .map((column) => ({
          ...(COLUMNS_AND_STATS[column.statisticKey || column.key] || {}),
          ...column,
        }))
        .map((column: ColumnOrRowWithDefinitions) =>
          autoGroupColumn
            ? column
            : (_.omit(column, ['grouping', 'rowGroupOrder', 'aggregationFunction']) as ColumnOrRowWithDefinitions),
        ),
  );

  getConditionTableColumns: (state: {
    columns: ColumnOrRowWithDefinitions[];
    workingSetOfItems: { id: string }[];
  }) => ConditionTableColumnsAndRows = createSelector(
    ({ columns }) => columns,
    deepEqualSelector(
      ({ workingSetOfItems }) => workingSetOfItems,
      (items) => items.map((item) => item.id),
    ),
    (columnsWithDefinition, itemIds) => {
      const [rows, columns] = _.partition(columnsWithDefinition, (column) => isPropertyOrStatOrMetricColumn(column));
      return {
        rows: rows
          .map((row) => ({ ...row, isPropertyOrStatColumn: isPropertyOrStatColumn(row) }))
          .filter((column) => !column.metricId || itemIds.some((id) => id === column.metricId)),
        columns,
      };
    },
  );

  getColumnsWithIndex: (state: {
    columns: ColumnOrRowWithDefinitions[];
    conditionTableRows: ColumnOrRowWithDefinitions[];
    mode: TableBuilderMode;
  }) => ColumnWithIndex[] = createSelector(
    ({ mode }) => mode,
    ({ conditionTableRows }) => conditionTableRows,
    deepEqualSelector(
      ({ columns }) => columns,
      (columns) => columns.map((column) => ({ key: column.key, filter: column.filter, sort: column.sort })),
    ),
    (mode, conditionTableRows) =>
      tableBuilder.getColumnsWithIndex(mode === TableBuilderMode.Condition ? conditionTableRows : this.columns),
  );

  getSimpleTableData: (state: {
    columnsWithIndex: ColumnWithIndex[];
    rawTableData: SimpleTableRow[];
  }) => SimpleTableRow[] = createSelector(
    [({ columnsWithIndex }) => columnsWithIndex, ({ rawTableData }) => rawTableData],
    (columnsWithIndex, rawTableData) => {
      return tableBuilder.processSimpleTableData(rawTableData, this.columns, columnsWithIndex);
    },
  );

  getConditionTableData: (state: {
    columnsWithIndex: ColumnWithIndex[];
    rawTableData: ConditionTableData;
    isTransposed: boolean;
  }) => ConditionTableData = createSelector(
    [
      ({ columnsWithIndex }) => columnsWithIndex,
      ({ rawTableData }) => rawTableData,
      ({ isTransposed }) => isTransposed,
    ],
    (columnsWithIndex, rawTableData, isTransposed) => {
      return {
        headers: rawTableData.headers,
        capsules: tableBuilder.processConditionTableData({
          isTransposed,
          data: rawTableData.capsules,
          columnsWithIndex,
          conditionTableRows: this.conditionTableColumns.rows,
          tableItems: this.getTableItemsProcess(),
        }),
      };
    },
  );

  /**
   * Get the callback to pass to the formula service, that creates condition formulas from table items.
   * Used for computing the Condition Table.
   *
   * @param items - list of items in the table
   * @param itemColumnsMap - map between metrics and the multiple columns they correspond to in the computed table
   * @param conditionPropertyColumns - The columns that must use a parameter expression in order to get the
   * property from the backing condition
   * @param isRunAcrossAssets - True if it formula is being run across assets
   * @param statColumns - All statistic columns being used
   * @returns callback that gets the condition formulas for conditions/metrics
   */
  getBuildConditionFormulaFunction(
    items: any[],
    itemColumnsMap,
    conditionPropertyColumns: any[],
    isRunAcrossAssets: boolean,
    statColumns: StatColumn[],
  ): BuildConditionFormulaCallback {
    const idOfItemInAssetTree: string | undefined =
      _.find(items, (item) => !_.isEmpty(item.assets))?.id ?? _.find(statColumns, 'signalId')?.signalId;
    return (ids, parameters) => {
      const idToShortName = _.invert(parameters);
      const formula = _.chain(ids)
        .map((id) => {
          const identifier = idToShortName[id];
          const formulaParts = [`$${identifier}`];
          if (_.find(items, { id }).itemType === ITEM_TYPES.METRIC) {
            const columns = _.values(itemColumnsMap[id]);
            formulaParts.push('toCondition()');
            formulaParts.push(
              ..._.chain(columns)
                .filter('metricProperty')
                .map(
                  (column: MetricPropertyColumn) =>
                    `renameProperty('${column.metricProperty}', '${column.propertyName}')`,
                )
                .value(),
            );
            formulaParts.push(
              ..._.chain(columns)
                .filter('expression')
                .map((column: MetricPropertyColumn) => {
                  const propIdentifier = `${identifier}_${column.key.split('_')[1]}`;
                  parameters[propIdentifier] = `$${identifier}.${column.expression}`;
                  return `setProperty('${column.propertyName}', $${propIdentifier})`;
                })
                .value(),
            );
          }

          formulaParts.push(
            ..._.map(conditionPropertyColumns, (column, index) => {
              // If going across assets and the condition is not in the asset tree then find another item that is in
              // the asset tree so the user can see from which asset the statistic came (CRAB-33710).
              let conditionIdentifier = identifier;
              if (
                column.key === COLUMNS_AND_STATS.asset.key &&
                isRunAcrossAssets &&
                idOfItemInAssetTree &&
                _.isEmpty(_.find(items, { id })?.assets)
              ) {
                if (idToShortName[idOfItemInAssetTree]) {
                  conditionIdentifier = idToShortName[idOfItemInAssetTree];
                } else {
                  conditionIdentifier = `${getShortIdentifier(index)}_assetItem`;
                  parameters[conditionIdentifier] = idOfItemInAssetTree;
                }
              }
              const propertyIdentifier = `${conditionIdentifier}_conditionProp_${getShortIdentifier(index)}`;
              const expression = column.propertyExpression ?? `property('${column.key}')`;
              parameters[propertyIdentifier] = `$${conditionIdentifier}.${expression}`;
              return `setProperty('${column.key}', $${propertyIdentifier})`;
            }),
          );

          return formulaParts.join('.');
        })
        .join(', ')
        .value();
      return { formula, parameters };
    };
  }

  /**
   * Get the callback to pass to the formula service, that creates formulas for signal statistic columns
   * Used for computing the Condition Table.
   *
   * @param statColumns - list of statistic columns in the table
   * @returns callback that gets the condition formulas for conditions/metrics
   */
  getBuildStatFormulaFunction(statColumns: any[]): BuildStatFormulaCallback {
    const isRunAcrossAssets = !!this.getAssetId();
    const isHomogenizeUnits = this.getIsHomogenizeUnits();
    const itemTransformer = (itemIdentifier, signalId, parameters) => {
      if (isRunAcrossAssets) {
        const item = sqTrendSeriesStore.findItem(signalId);
        if (!isStringSeriesUtil(item)) {
          const mapIdsToShortIdentifiers = _.invert(parameters);
          const itemIdentifier = mapIdsToShortIdentifiers[signalId];
          if (isHomogenizeUnits) {
            const fixedItemUOMIdentifier = `fixed_${itemIdentifier}_${ITEM_UOM}`;
            parameters[fixedItemUOMIdentifier] = `$${signalId}.property('${SeeqNames.Properties.ValueUom}')`;
            return `$${itemIdentifier}.convertUnits($${fixedItemUOMIdentifier})`;
          } else {
            return `$${itemIdentifier}.convertUnits('')`;
          }
        }
      }
      return itemIdentifier;
    };
    return getBuildStatFormulaFunctionCallback(statColumns, itemTransformer);
  }

  /**
   * Get the formula fragment for a property column for a particular item.
   * Used when computing the Simple Table.
   *
   * @param column - the column we're trying to add to the formula
   * @param item - the item we're adding the column for
   * @param itemIdentifier - the short id for the item to use in the formula
   */
  getSimpleTablePropertyColumnFormula(column: PropertyColumn, item, itemIdentifier: string): string {
    return _.cond([
      [
        _.matches({ key: COLUMNS_AND_STATS.asset.key }),
        () => `$${itemIdentifier}.parentProperty('${SeeqNames.Properties.Name}')`,
      ],
      [_.matches({ key: 'fullpath' }), () => `$${itemIdentifier}.ancestors(' >> ')`],
      [
        _.property('propertyName'),
        ({ propertyName }) =>
          `$${itemIdentifier}.property('${_.isFunction(propertyName) ? propertyName(item.itemType) : propertyName}')`,
      ],
      [_.matches({ type: TableBuilderColumnType.Property }), ({ key }) => `$${itemIdentifier}.property('${key}')`],
      [
        _.stubTrue,
        ({ key }) => {
          throw new TypeError(`${key} column is not supported in simple table formula`);
        },
      ],
    ])(column);
  }

  /**
   * Custom item/capsule properties used in the table.
   */
  getPropertyColumns: (columns: ColumnOrRowWithDefinitions[]) => PropertyColumn[] = createSelector(
    (columns) => columns,
    (columns) => tableBuilder.getPropertyColumns(columns),
  );

  /**
   * Finds the index of a column
   * @param key - Column key
   * @param isNotFoundAllowed - If true, does not throw if the column does not exist
   * @returns the index of the column. If the column is not found, it returns -1
   */
  getColumnIndex(key: string, isNotFoundAllowed = false): number {
    return tableBuilder.getColumnIndex(this.getColumns(), key, isNotFoundAllowed);
  }

  /**
   * Returns the column with the specified key.
   * @param key - Column key
   *
   * @returns the column with the specified key.
   */
  getColumn(key: string): ColumnOrRow {
    return _.find(this.state.get(['columns', this.state.get('mode')]), {
      key,
    }) as ColumnOrRow;
  }

  /**
   * Returns the column definition with the specified key.
   * @param key - Column key
   *
   * @returns the column definition with the specified key.
   */
  getColumnDefinition(key: string): ColumnOrRowWithDefinitions {
    return this.columns.find((column) => column.key === key);
  }

  /**
   * Dehydrates the item by retrieving the current set parameters in view
   * @returns {Object} An object with the state properties as JSON
   */
  dehydrate() {
    return _.omit(this.state.serialize(), [
      'tableData',
      'columnIdToAutoHeight',
      'clipboardStyle',
      'distinctStringValueMap',
      'fetchFailedMessage',
    ]);
  }

  /**
   * Rehydrates item from dehydrated state
   *
   * @param {Object} dehydratedState - State object that should be restored
   */
  rehydrate(dehydratedState) {
    this.state.deepMerge(dehydratedState);
  }

  protected readonly handlers = {
    SIMPLE_THRESHOLD_METRIC_CREATED: this.handleSimpleMetricCreated,
    TABLE_BUILDER_SET_MODE: this.setMode,
    TABLE_BUILDER_ADD_COLUMN: this.addColumn,
    TABLE_BUILDER_ADD_METRIC_TO_CONDITION_TABLE: this.addMetricColumnToConditionTable,
    TABLE_BUILDER_REMOVE_COLUMN: this.removeColumn,
    TABLE_BUILDER_MOVE_COLUMN: this.moveColumn,
    TABLE_BUILDER_SET_COLUMN_BACKGROUND: this.setColumnBackground,
    TABLE_BUILDER_SET_COLUMN_TEXT_ALIGN: this.setColumnTextAlign,
    TABLE_BUILDER_SET_COLUMN_TEXT_COLOR: this.setColumnTextColor,
    TABLE_BUILDER_SET_COLUMN_TEXT_STYLE: this.setColumnTextStyle,
    TABLE_BUILDER_SET_HEADER_BACKGROUND: this.setHeaderBackground,
    TABLE_BUILDER_SET_HEADER_TEXT_ALIGN: this.setHeaderTextAlign,
    TABLE_BUILDER_SET_HEADER_TEXT_COLOR: this.setHeaderTextColor,
    TABLE_BUILDER_SET_HEADER_TEXT_STYLE: this.setHeaderTextStyle,
    TABLE_BUILDER_SET_STYLE_TO_ALL_COLUMNS: this.setStyleForAllColumns,
    TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS: this.setStyleForAllHeaders,
    TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS_AND_COLUMNS: this.setStyleForAllHeadersAndColumns,
    TABLE_BUILDER_COPY_STYLE: this.copyStyle,
    TABLE_BUILDER_PASTE_STYLE_ON_HEADER: this.pasteStyleOnHeader,
    TABLE_BUILDER_PASTE_STYLE_ON_COLUMN: this.pasteStyleOnColumn,
    TABLE_BUILDER_PASTE_STYLE_ON_HEADER_AND_COLUMN: this.pasteStyleOnHeaderAndColumn,
    TABLE_BUILDER_SET_COLUMN_FILTER: this.setColumnFilter,
    TABLE_BUILDER_SORT_BY_COLUMN: this.sortByColumn,
    TABLE_BUILDER_SET_CELL_TEXT: this.setCellText,
    TABLE_BUILDER_SET_COLUMN_WIDTH: this.setColumnWidth,
    TABLE_BUILDER_SET_HEADER_TEXT: this.setHeaderText,
    TABLE_BUILDER_SET_HEADERS_TYPE: this.setHeadersType,
    TABLE_BUILDER_SET_HEADERS_FORMAT: this.setHeadersFormat,
    TABLE_BUILDER_SET_HEADERS_PROPERTY: this.setHeadersProperty,
    TABLE_BUILDER_PUSH_CONDITION_DATA: this.setConditionTableData,
    TABLE_BUILDER_PUSH_SIMPLE_DATA: this.setSimpleTableData,
    TABLE_BUILDER_SET_HEADER_OVERRIDE: this.setHeaderOverridden,
    TABLE_BUILDER_SET_IS_TRANSPOSED: this.setIsTransposed,
    TABLE_BUILDER_SET_ASSET_ID: this.setAssetId,
    TABLE_BUILDER_SET_HOMOGENIZE_UNITS: this.setIsHomogenizeUnits,
    TABLE_BUILDER_SET_IS_MIGRATING: this.setIsMigrating,
    TABLE_BUILDER_SET_IS_TABLE_STRIPED: this.setIsTableStriped,
    TABLE_BUILDER_SET_USE_SIGNAL_COLORS_IN_CHART: this.setUseSignalColorsInChart,
    TABLE_BUILDER_SET_FETCH_FAILED_MESSAGE: this.setFetchFailedMessage,
    TABLE_BUILDER_SET_CHART_VIEW: this.setChartView,
    TABLE_BUILDER_SET_CHART_VIEW_SETTINGS: this.setChartViewSettings,
    TABLE_BUILDER_SET_CONDITION_CHART_VIEW: this.setConditionChartView,
    TABLE_BUILDER_SET_ARE_ALL_ROWS_EXPANDED: this.setAreAllRowsExpanded,
    TABLE_BUILDER_SET_CHART_VIEW_CONDITION_SETTINGS: this.setChartViewConditionSettings,
    TABLE_BUILDER_SET_SIMPLE_DISTINCT_STRING_VALUES: this.setSimpleDistinctStringValues,
    TABLE_BUILDER_SET_CONDITION_DISTINCT_STRING_VALUES: this.setConditionDistinctStringValues,
    TABLE_BUILDER_REMOVE_ITEMS: this.removeItems,
    TREND_SET_PANEL_SORT: this.setSortedTableData,
    TREND_REMOVE_ITEMS: this.removeItems,
    TREND_SWAP_ITEMS: this.swapItems,
    TABLE_BUILDER_TOGGLE_ROW_GROUPING: this.toggleRowGrouping,
    TABLE_BUILDER_GROUP_BY_ROW: this.enableRowGroup,
    TABLE_BUILDER_UNGROUP_ROW: this.disableRowGroup,
    TABLE_BUILDER_SET_ROW_GROUP_SHOW: this.setRowGroupShow,
    TABLE_BUILDER_SET_ROW_GROUP_ORDER: this.setRowGroupOrder,
    TABLE_BUILDER_SET_AGGREGATION_FUNCTION: this.setAggregationFunction,
  };

  /**
   * Sets the mode of the table builder
   *
   * @param payload - Object container for arguments
   * @param payload.mode - The mode
   */
  setMode(payload: { mode: TableBuilderMode }) {
    this.state.set('mode', payload.mode);
  }

  /**
   * Adds a new table column.
   *
   * @param {Object} payload - Object container. Can either be a special type or one of the predefined columns.
   * @param {TableBuilderColumnType} [payload.type] - The column type
   * @param {string} [payload.style] - The column style for property columns
   * @param {string} [payload.propertyName] - The property name, required if type is TableBuilderColumnType.Property
   * @param {PropertyColumn} [payload.column] - One of the predefined columns
   */
  addColumn(payload) {
    const columnDefinition = withDefaultFormatting(
      _.cond([
        [_.matches({ type: TableBuilderColumnType.Text }), () => ({ key: base64guid(), type: payload.type })],
        [
          (p) => isPropertyColumnType(p as ColumnOrRow),
          () => ({
            key: payload.propertyName,
            type: payload.type,
            style: payload.style,
          }),
        ],
        [
          _.property('signalId'),
          () => ({
            // Can add the same statistic for many series, so a unique key must be created
            key: this.getColumnKeyFromSignalId(payload.column, payload.signalId),
            statisticKey: payload.column.key,
            signalId: payload.signalId,
          }),
        ],
        [
          _.property('metricId'),
          () => ({
            key: payload.metricId,
            metricId: payload.metricId,
          }),
        ],
        [
          _.property('column'),
          // column key is enough for a predefined column. We don't want to store and persist unnecessary attributes
          () => ({ key: payload.column.key }),
        ],
        [
          _.stubTrue,
          () => {
            throw new TypeError(`Unknown column type ${payload}`);
          },
        ],
      ])(payload),
    );

    if (!this.isColumnKeyEnabled(columnDefinition.key)) {
      const mode = payload.modeOverride ?? this.state.get('mode');
      const cursor = this.state.select('columns', mode);
      // check if we should add the column to a predefined position
      if (PREDEFINED_COLUMN_INDEX[columnDefinition.key] >= 0) {
        cursor.splice([PREDEFINED_COLUMN_INDEX[columnDefinition.key], 0, columnDefinition]);
      } else {
        cursor.push(columnDefinition);
      }
    }
  }

  addMetricColumnToConditionTable(payload: { metricId: string }) {
    this.addColumn({ ...payload, modeOverride: TableBuilderMode.Condition });
  }

  /**
   * Removes the specified table column.
   *
   * @param {Object} payload - Object container
   * @param {string} payload.key - The key that identifies the column
   */
  removeColumn({ key }) {
    const columnIndex = this.getColumnIndex(key);
    const column = this.getColumn(key);
    // remove sort first so that we can update the sort level of the remaining columns
    this.removeSort(key);
    this.state.splice(['columns', this.state.get('mode')], [columnIndex, 1]);

    const chartColumnIndex = _.findIndex(this.chartViewSettings.columns, (columns) => columns === key);
    const chartCategoriesColumns = _.findIndex(
      this.chartViewSettings.categoryColumns,
      (categoryColumns) => categoryColumns === key,
    );
    if (chartColumnIndex > -1 || chartCategoriesColumns > -1) {
      this.state.splice(['chartView', 'settings', 'columns'], [chartColumnIndex, 1]);
      this.state.splice(['chartView', 'settings', 'categoryColumns'], [chartCategoriesColumns, 1]);
    }

    if (this.getIsSimpleMode()) {
      _.forEach(this.state.get('tableData', TableBuilderMode.Simple), (row, rowIndex) => {
        this.state.splice(['tableData', TableBuilderMode.Simple, rowIndex, 'cells'], [columnIndex, 1]);
      });
      const tableData = this.state.get('tableData', TableBuilderMode.Simple);
      this.state.set(['tableData', TableBuilderMode.Simple], [...tableData]);
    } else {
      if (isPropertyOrStatOrMetricColumn(column)) {
        const dataIndex = _.findIndex(this.state.get(['tableData', TableBuilderMode.Condition, 'headers']), {
          key: column.key,
        });
        this.state.splice(['tableData', TableBuilderMode.Condition, 'headers'], [dataIndex, 1]);
        _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (capsules, i) => {
          this.state.splice(['tableData', TableBuilderMode.Condition, 'capsules', i, 'values'], [dataIndex, 1]);
        });
        const tableData = this.state.get('tableData', TableBuilderMode.Condition);
        this.state.set(['tableData', TableBuilderMode.Condition], { ...tableData });
      }
    }
  }

  /**
   * Moves the specified table column to a new position.
   *
   * @param {Object} payload - Object container
   * @param {string} payload.key - The key that identifies the column
   * @param {string} payload.newKey - The key that specifies the column of the new position
   */
  moveColumn(payload) {
    const cursor = this.state.select('columns', this.state.get('mode'));
    const index = this.getColumnIndex(payload.key);
    const newIndex = this.getColumnIndex(payload.newKey);
    const column = cursor.get(index);
    cursor.splice([index, 1]);
    cursor.splice([newIndex, 0, column]);
    if (this.getIsSimpleMode()) {
      _.forEach(this.state.get('tableData', TableBuilderMode.Simple), (row, rowIndex) => {
        const cellCursor = this.state.select('tableData', TableBuilderMode.Simple, rowIndex, 'cells');
        const cellValue = cellCursor.get(index);
        cellCursor.splice([index, 1]);
        cellCursor.splice([newIndex, 0, cellValue]);
      });
    } else if (isPropertyOrStatOrMetricColumn(column)) {
      const headerCursor = this.state.select('tableData', TableBuilderMode.Condition, 'headers');
      const headerIndex = _.findIndex(headerCursor.get(), {
        key: payload.key,
      });
      const newHeaderIndex = _.findIndex(headerCursor.get(), {
        key: payload.newKey,
      });
      const headerValue = headerCursor.get(headerIndex);
      headerCursor.splice([headerIndex, 1]);
      headerCursor.splice([newHeaderIndex, 0, headerValue]);
      _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (row, rowIndex) => {
        const valueCursor = this.state.select('tableData', TableBuilderMode.Condition, 'capsules', rowIndex, 'values');
        const value = valueCursor.get(headerIndex);
        valueCursor.splice([headerIndex, 1]);
        valueCursor.splice([newHeaderIndex, 0, value]);
      });
    }
  }

  /**
   * Sets the background color for a table column (header excluded).
   *
   * @param {Object} payload - Object container
   * @param {string} payload.key - The key of the column
   * @param {String} payload.color - Background color for the column
   */
  setColumnBackground(payload) {
    this.state.set(
      ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'backgroundColor'],
      payload.color,
    );
  }

  setColumnTextAlign(payload) {
    this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'textAlign'], payload.align);
  }

  setColumnTextColor(payload) {
    this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'textColor'], payload.color);
  }

  setColumnTextStyle(payload) {
    this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'textStyle'], payload.style);
  }

  /**
   * Sets the background color for a table header.
   *
   * @param {Object} payload - Object container
   * @param {string} payload.key - The key of the column
   * @param {String} payload.color - Background color for the header
   */
  setHeaderBackground(payload) {
    this.state.set(
      ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerBackgroundColor'],
      payload.color,
    );
  }

  setHeaderTextAlign(payload) {
    this.state.set(
      ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerTextAlign'],
      payload.align,
    );
  }

  setHeaderTextColor(payload) {
    this.state.set(
      ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerTextColor'],
      payload.color,
    );
  }

  setHeaderTextStyle(payload) {
    this.state.set(
      ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'headerTextStyle'],
      payload.style,
    );
  }

  setStyleForAllColumns(payload) {
    const sourceColumn = this.getColumn(payload.key);
    _.forEach(this.getColumns(), (column, index) => {
      this.state.merge(['columns', this.state.get('mode'), index], {
        backgroundColor: sourceColumn.backgroundColor,
        textAlign: sourceColumn.textAlign,
        textColor: sourceColumn.textColor,
        textStyle: sourceColumn.textStyle,
      });
    });
  }

  setStyleForAllHeaders(payload) {
    const sourceColumn = this.getColumn(payload.key);
    _.forEach(this.getColumns(), (column, index) => {
      this.state.merge(['columns', this.state.get('mode'), index], {
        headerBackgroundColor: sourceColumn.headerBackgroundColor,
        headerTextAlign: sourceColumn.headerTextAlign,
        headerTextColor: sourceColumn.headerTextColor,
        headerTextStyle: sourceColumn.headerTextStyle,
      });
    });
  }

  setStyleForAllHeadersAndColumns(payload) {
    this.setStyleForAllColumns(payload);
    this.setStyleForAllHeaders(payload);
  }

  copyStyle(payload) {
    const sourceColumn = this.getColumn(payload.key);
    this.state.set('clipboardStyle', {
      backgroundColor: sourceColumn.backgroundColor,
      textAlign: sourceColumn.textAlign,
      textColor: sourceColumn.textColor,
      textStyle: sourceColumn.textStyle,
      headerBackgroundColor: sourceColumn.headerBackgroundColor,
      headerTextAlign: sourceColumn.headerTextAlign,
      headerTextColor: sourceColumn.headerTextColor,
      headerTextStyle: sourceColumn.headerTextStyle,
    });
  }

  pasteStyleOnHeader(payload) {
    const clipboardStyle = this.state.get('clipboardStyle');
    this.state.merge(['columns', this.state.get('mode'), this.getColumnIndex(payload.key)], {
      headerBackgroundColor: clipboardStyle.headerBackgroundColor,
      headerTextAlign: clipboardStyle.headerTextAlign,
      headerTextColor: clipboardStyle.headerTextColor,
      headerTextStyle: clipboardStyle.headerTextStyle,
    });
  }

  pasteStyleOnColumn(payload) {
    const clipboardStyle = this.state.get('clipboardStyle');
    this.state.merge(['columns', this.state.get('mode'), this.getColumnIndex(payload.key)], {
      backgroundColor: clipboardStyle.backgroundColor,
      textAlign: clipboardStyle.textAlign,
      textColor: clipboardStyle.textColor,
      textStyle: clipboardStyle.textStyle,
    });
  }

  pasteStyleOnHeaderAndColumn(payload) {
    this.pasteStyleOnColumn(payload);
    this.pasteStyleOnHeader(payload);
  }

  /**
   * Set (or unset) a filter on a column. If payload.filter is undefined, any existing filter is removed.
   *
   * @param payload - object container for args
   * @param payload.key - column key (simple or condition property/stat column) or item id (condition metric)
   * @param payload.filter - column filter (can be undefined)
   */
  setColumnFilter(payload: { key: string; filter: TableColumnFilter }) {
    if (payload.filter) {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'filter'], payload.filter);
    } else {
      this.state.unset(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'filter']);
    }
  }

  /**
   * Set (or unset) a sort criterion on a column. If payload.direction is 'none', the sort criterion is removed.
   * @param payload - object container for args
   * @param payload.key - column key
   * @param payload.direction - column sort order
   */
  sortByColumn(payload: { key: string; direction: string }) {
    if (_.isUndefined(payload.direction)) {
      this.removeSort(payload.key);
    } else {
      this.addOrUpdateSort(payload.key, payload.direction);
    }
  }

  /**
   * Set or update the sort criterion on the specified column. When sorting on multiple levels, the last column
   * added is the most important.
   * @param key - column key or itemId
   * @param direction - column sort order
   */
  addOrUpdateSort(key: string, direction: string) {
    const columnIndex = this.getColumnIndex(key);
    if (_.has(this.getColumn(key), 'sort')) {
      this.state.set(['columns', this.state.get('mode'), columnIndex, 'sort', 'direction'], direction);
    } else {
      this.incrementSortLevels();
      this.state.set(['columns', this.state.get('mode'), columnIndex, 'sort'], { direction, level: 1 });
    }
  }

  /**
   * Increments the sort level on all sorts
   */
  incrementSortLevels() {
    _.chain(this.getColumns())
      .filter((column) => !!column.sort?.level)
      .forEach((column) => {
        const index = this.getColumnIndex(column.key);
        this.state.set(['columns', this.state.get('mode'), index, 'sort', 'level'], column.sort.level + 1);
      })
      .value();
  }

  /**
   * Removes the sort criterion from the specified column.
   * @param key - column key or itemId
   */
  removeSort(key: string) {
    // we may not have a sort level. This function is also called from removeColumn
    const sortLevel: number = this.getColumn(key)?.sort?.level ?? Infinity;
    const maybeColumn = this.getColumn(key);
    if (_.isUndefined(maybeColumn)) {
      return;
    }
    const columnIndex = this.getColumnIndex(key);
    this.state.unset(['columns', this.state.get('mode'), columnIndex, 'sort']);
    _.forEach(this.getColumns(), (column, index) => {
      if (column.sort?.level > sortLevel) {
        this.state.set(['columns', this.state.get('mode'), index, 'sort', 'level'], column.sort.level - 1);
      }
    });
  }

  /**
   * Sets the text for a table column cell.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.key - Column key
   * @param {String} payload.text - Text for the cell
   * @param {String} [payload.cellKey] - The identifier for the cell. If not specified the column header text
   * will be set.
   */
  setCellText(payload) {
    const index = this.getColumnIndex(payload.key);
    if (
      payload.cellKey &&
      this.state.get(['columns', this.state.get('mode'), index, 'type']) !== TableBuilderColumnType.Text
    ) {
      throw new TypeError('Can only set text on a column of type text');
    }

    if (payload.cellKey) {
      this.state.set(['columns', this.state.get('mode'), index, 'cells', payload.cellKey], _.trim(payload.text));
    } else {
      this.state.set(['columns', this.state.get('mode'), index, 'header'], _.trim(payload.text));
    }
  }

  setColumnWidth({ key, newWidth }: { key: string; newWidth: number | undefined }) {
    const mode = this.mode;
    const index = this.getColumnIndex(key, true);
    const path = index === -1 ? ['otherColumns', mode, key] : ['columns', mode, index];
    const columnCursor = this.state.select(path);

    if (newWidth === undefined) {
      columnCursor.unset('width');
    } else {
      columnCursor.set('width', newWidth);
    }

    if (_.isEmpty(columnCursor.get())) {
      columnCursor.unset();
    }
  }

  /**
   * Sets the header override flag for a column and disables the overridden flag for other columns.
   *
   * @param payload - Object container
   * @param payload.columnKey - The column key.
   */
  setHeaderOverridden({ columnKey }: { columnKey: string }) {
    _.forEach(this.state.get('columns', this.state.get('mode')), (column, columnIndex) => {
      if (columnKey === column.key) {
        this.state.set(['columns', this.state.get('mode'), columnIndex, 'headerOverridden'], true);
      } else {
        this.state.unset(['columns', this.state.get('mode'), columnIndex, 'headerOverridden']);
      }
    });
  }

  /**
   * Sets the text for a table column header.
   *
   * @param {Object} payload - Object container
   * @param {Number} payload.columnKey - Column key
   * @param {String} payload.text - Text for the header
   */
  setHeaderText(payload) {
    const columnIndex = this.getColumnIndex(payload.columnKey);
    this.state.set(['columns', this.state.get('mode'), columnIndex, 'header'], _.trim(payload.text));
  }

  /**
   * Sets the header type for table columns that display static headers (all columns in condition mode, and the name
   * column in simple mode).
   *
   * @param {Object} payload - Object container
   * @param {TableBuilderHeaderType} payload.type - The type of header to display
   */
  setHeadersType(payload) {
    this.state.set(['headers', this.state.get('mode'), 'type'], payload.type);
  }

  /**
   * Sets the date format used for headers of static headers (all columns in condition mode, and the name column
   * in simple mode).
   *
   * @param {Object} payload - Object container
   * @param {String} payload.format - A string that can be passed to moment's format()
   */
  setHeadersFormat(payload) {
    this.state.set(['headers', this.state.get('mode'), 'format'], payload.format);
  }

  /**
   * Sets the name of the capsule property used for headers of metric value columns when the type is CapsuleProperty.
   *
   * @param {Object} payload - Object container
   * @param {String} payload.property - The capsule property name
   */
  setHeadersProperty(payload) {
    if (_.isEmpty(payload.property)) {
      this.setHeadersType({ type: TableBuilderHeaderType.StartEnd });
      this.state.unset(['headers', this.state.get('mode'), 'property']);
    } else {
      this.state.set(['headers', this.state.get('mode'), 'property'], payload.property);
    }
  }

  /**
   * Sorts the table data and sets it in the store.
   */
  setSortedTableData() {
    // We only need the value of the sort flag here, so just wait for 'sqTrendStore'.
    // The items are already in their store.
    this.waitFor(['sqTrendStore'], () => {
      if (!this.getIsSimpleMode()) {
        const itemIds = _.map(this.getTableItemsProcess(), 'id');
        const sortByItemIndex = (field) => (cell) => {
          const index = _.indexOf(itemIds, cell[field]);
          return index === -1 ? itemIds.length : index;
        };
        this.state.set(
          ['tableData', TableBuilderMode.Condition, 'headers'],
          _.sortBy(this.state.get('tableData', TableBuilderMode.Condition, 'headers'), [sortByItemIndex('key')]),
        );
        _.forEach(this.state.get('tableData', TableBuilderMode.Condition, 'capsules'), (capsules, i) => {
          this.state.set(
            ['tableData', TableBuilderMode.Condition, 'capsules', i, 'values'],
            _.sortBy(capsules.values, [sortByItemIndex('formulaItemId')]),
          );
        });
      } else {
        const tableData = this.maybeApplyDefaultSort(
          this.state.get('tableData', TableBuilderMode.Simple) as SimpleTableRow[],
          this.getTableItemsProcess(),
          this.getColumns(),
        );
        this.state.set(['tableData', TableBuilderMode.Simple], tableData);
      }
    });
  }

  /**
   * Removes metric information from all columns. Used to clear metric information before pushing new data into
   * the table.
   */
  removeAllMetricInfo() {
    const columnCount = this.getColumns().length;
    for (let i = 0; i < columnCount; i++) {
      this.state.unset(['columns', this.state.get('mode'), i, 'metrics']);
    }
  }

  /**
   * Builds the simple table data for presentation using the data returned from the API and the map of column
   * positions.
   *
   * @param tableAndColumnPositions - Array of tuples for result tables and column positions for each item type,
   * #getSimpleTableFormulaAndParameters()
   */
  setSimpleTableData({
    tableAndColumnPositions,
  }: {
    tableAndColumnPositions: Array<[SimpleTableResultTable, ColumnPosition[]]>;
  }) {
    const resultRowIdIndex = 2;
    const columns = this.columns;
    const columnIdToAutoHeight: Record<string, boolean> = {};
    const items = this.getTableItemsProcess();
    const tableDataByIteratingOnDataOnly: SimpleTableRow[] = _.chain(tableAndColumnPositions)
      .flatMap(([table, colPositions]: [SimpleTableResultTable, ColumnPosition[]]) => {
        if (_.isNil(table)) {
          return table;
        }
        return _.chain(table.data)
          .flatMap((resultRow) => {
            const item = _.find(items, { id: resultRow[resultRowIdIndex] }) || _.find(items, { id: _.last(resultRow) });
            let ignoreRow = false;
            const itemId =
              resultRow[
                _.find(colPositions, {
                  key: SIMPLE_TABLE_ID_COLUMN,
                }).index
              ];
            const formattedRow = {
              itemId,
              formulaItemId: item.id,
              cells: _.map(columns, (column) => {
                const getValue = (index) => {
                  const formattedValue = formatMetricValue(resultRow[index], item.formatOptions, column);
                  if (
                    (columnIdToAutoHeight[column.key] === undefined || columnIdToAutoHeight[itemId] === undefined) &&
                    formattedValue.includes('\n')
                  ) {
                    columnIdToAutoHeight[column.key] = true;
                    columnIdToAutoHeight[itemId] = true;
                  }
                  const rawValue = resultRow[index];
                  const headerUnit = table.headers[index].units;

                  let priorityColor: string | undefined;

                  if (item.itemType !== ITEM_TYPES.METRIC) {
                    const metricTableData = _.find(
                      tableAndColumnPositions,
                      ([table, columnPositions]) =>
                        table &&
                        columnPositions.length > 0 &&
                        _.some(
                          columnPositions,
                          (columnPosition) => columnPosition.key === SeeqNames.Properties.MeasuredItemId,
                        ),
                    );

                    const [metricTable, metricColumnPositions] = metricTableData || [undefined, []];
                    if (!_.isNil(metricTable)) {
                      const measuredItemIndex = _.find(
                        metricColumnPositions,
                        (columnPosition) => columnPosition.key === SeeqNames.Properties.MeasuredItemId,
                      ).index;
                      const aggregationFunctionIndex = _.find(
                        metricColumnPositions,
                        (columnPosition) => columnPosition.key === SeeqNames.Properties.AggregationFunction,
                      ).index;

                      const metricMeasuringThisItem = _.find(
                        metricTable.data,
                        (dataRow) =>
                          dataRow[measuredItemIndex] === itemId &&
                          column.stat &&
                          dataRow[aggregationFunctionIndex] === column.stat,
                      );

                      if (!_.isNil(metricMeasuringThisItem)) {
                        const columnPositionForTheMetricValue = _.find(
                          metricColumnPositions,
                          (metricColumnPosition) => metricColumnPosition.key === COLUMNS_AND_STATS.metricValue.key,
                        );
                        priorityColor = metricMeasuringThisItem[columnPositionForTheMetricValue.index + 1];
                      }
                    }
                  }

                  const unitIndex = _.find(colPositions, (columnPosition) =>
                    _.includes(columnPosition.key, COLUMNS_AND_STATS.valueUnitOfMeasure.key),
                  ).index;

                  function determineUnits(
                    headerUnit: string,
                    unitFromData: any,
                    column: ColumnOrRowWithDefinitions,
                    shouldClearUnits: boolean,
                  ): any {
                    if (headerUnit === STRING_UOM) {
                      return undefined;
                    } else if (
                      headerUnit === undefined ||
                      (headerUnit === '' && shouldClearUnits) ||
                      column.type === TableBuilderColumnType.Property ||
                      column.propertyExpression ||
                      [
                        COLUMNS_AND_STATS['statistics.percentDuration'].key,
                        COLUMNS_AND_STATS['statistics.percentGood'].key,
                        COLUMNS_AND_STATS['statistics.count'].key,
                        COLUMNS_AND_STATS['statistics.totalDuration'].key,
                      ].includes(column.key)
                    ) {
                      return headerUnit;
                    } else {
                      return unitFromData;
                    }
                  }

                  const isHomogenizeUnits = this.getIsHomogenizeUnits();
                  const isRunAcrossAssets = !!this.getAssetId();
                  const unitFromData = unitIndex !== -1 ? resultRow[unitIndex] : undefined;
                  const units = determineUnits(
                    headerUnit,
                    unitFromData,
                    column,
                    isRunAcrossAssets && !isHomogenizeUnits,
                  );

                  return {
                    value: formattedValue,
                    units,
                    rawValue,
                    ...(priorityColor && { priorityColor }),
                  };
                };

                const columnIndex = _.find(colPositions, {
                  key: column.key,
                });
                if (!!column.filter && (_.isNil(columnIndex) || _.isNil(resultRow[columnIndex.index]))) {
                  ignoreRow = true;
                }

                return _.cond([
                  [
                    _.isNil,
                    () => ({
                      value: formatMetricValue(null, item.formatOptions),
                      rawValue: null,
                    }),
                  ],
                  [
                    _.property('metricId'),
                    ({ index, metricId }) => ({
                      ...getValue(index),
                      priorityColor: resultRow[index + 1],
                      metricId,
                    }),
                  ],
                  // a simple metric does not have uom property, but we can still find it's unit if the 'valueMetric'
                  // is displayed. In this case the aggregation function returns the value plus the unit in the
                  // 'headers'
                  [
                    _.matches({
                      key: COLUMNS_AND_STATS.valueUnitOfMeasure.key,
                    }),
                    ({ index }) => {
                      const uom = resultRow[index];
                      if (uom === STRING_UOM) {
                        return { value: '' };
                      } else if (_.isNil(uom)) {
                        const maybeMetricIndex = _.find(colPositions, {
                          metricId: itemId,
                        })?.index;
                        if (!_.isNil(maybeMetricIndex)) {
                          return { value: table.headers[maybeMetricIndex].units };
                        }
                      }
                      return { value: uom };
                    },
                  ],
                  [
                    _.matches({
                      key: COLUMNS_AND_STATS['statistics.totalDuration'].key,
                    }),
                    ({ index }) => ({
                      value: formatDuration(secondsToMillis(resultRow[index])),
                      rawValue: resultRow[index],
                    }),
                  ],
                  [_.stubTrue, ({ index }) => getValue(index)],
                ])(columnIndex);
              }),
            };
            return ignoreRow ? [] : formattedRow;
          })
          .value();
      })
      .compact()
      .thru((rows) => this.maybeApplyDefaultSort(rows, items, columns))
      .value();

    this.state.set(['columnIdToAutoHeight', TableBuilderMode.Simple], columnIdToAutoHeight);
    this.updateOtherColumns(TableBuilderMode.Simple, tableDataByIteratingOnDataOnly);

    this.state.set('fetchFailedMessage', undefined);
    this.state.set(['tableData', TableBuilderMode.Simple], tableDataByIteratingOnDataOnly);
  }

  /**
   * Returns the data unchanged if we have user sort criteria. Otherwise, sort the data first by the item's position
   * in the details pane and then by asset.
   *
   * @param tableData - The data to sort
   * @param items - The items in the details pane
   * @param columns - The columns in the table
   */
  maybeApplyDefaultSort(tableData: SimpleTableRow[], items: any[], columns: any[]): SimpleTableRow[] {
    return tableBuilder.getMaxSortLevel(this.columns) > 0
      ? tableData
      : this.sortSimpleDataByItemsAndAsset(tableData, items, columns);
  }

  /**
   * Sorts the data first by the item's position in the details pane and then by asset.
   *
   * @param tableData - The data to sort
   * @param items - The items in the details pane
   * @param columns - The columns in the table
   */
  sortSimpleDataByItemsAndAsset(tableData: SimpleTableRow[], items: any[], columns: any[]): SimpleTableRow[] {
    const itemIds = _.map(items, 'id');
    const sortByItemIndex = (row) => {
      const index = _.indexOf(itemIds, row.formulaItemId);
      return index === -1 ? itemIds.length : index;
    };

    if (this.getAssetId()) {
      const assetIndex = _.findIndex(columns, ({ key }) => key === 'asset' || key === 'fullpath');
      if (assetIndex > -1) {
        return _.sortBy(tableData, [sortByItemIndex, `cells[${assetIndex}].value`]);
      }
    }

    return _.sortBy(tableData, [sortByItemIndex]);
  }

  private isSimpleTableData(data: SimpleTableRow[] | ConditionTableCapsule[]): data is SimpleTableRow[] {
    return 'itemId' in (data[0] ?? {});
  }

  /** Removes persisted columns that are not present in the provided data */
  private updateOtherColumns<T extends TableBuilderMode>(
    mode: T,
    data: T extends TableBuilderMode.Simple ? SimpleTableRow[] : ConditionTableCapsule[],
  ): void {
    const otherColumns = this.otherColumns[mode];
    const columnIds = Object.keys(otherColumns).filter((id) => !TRANSPOSE_HEADER_COLUMNS.includes(id));
    const existingColumns = new Map<string, boolean>();

    const updateExistingColumns = (rowId: string) => {
      if (otherColumns[rowId] && !existingColumns.has(rowId)) {
        existingColumns.set(rowId, true);
      }
    };

    if (this.isSimpleTableData(data)) {
      data.forEach((row) => updateExistingColumns(row.itemId));
    } else {
      data.forEach((row) => updateExistingColumns(row.id));
    }

    const otherColumnsCursor = this.state.select(['otherColumns', mode]);
    columnIds.forEach((id) => {
      if (!existingColumns.get(id)) {
        otherColumnsCursor.unset(id);
      }
    });
  }

  /**
   * Builds the table data for display.
   *
   * @param headers - The headers for each column
   * @param table - The capsules and their values
   * @param itemColumnsMap - A mapping of which columns go to which items
   * @param customPropertyName - The name of a custom property on each capsule
   */
  setConditionTableData({
    headers: rawHeaders,
    table,
    itemColumnsMap,
    customPropertyName,
  }: {
    headers: any[];
    table: CapsuleFormulaTableRow[];
    itemColumnsMap: ItemColumnsMap;
    customPropertyName: undefined | string;
  }) {
    if (table.length === MAX_CONDITION_TABLE_CAPSULES) {
      table.pop();
      this.state.set('hasMoreData', true);
    } else if (this.hasMoreData) {
      this.state.set('hasMoreData', false);
    }

    type AdornedColumn = ColumnOrRowWithDefinitions & { item: any; isStartOrEndColumn: boolean };
    const columns: AdornedColumn[] = this.conditionTableColumns.rows.map((column) => {
      const potentialItemId = column.signalId ?? column.metricId;
      const itemFinder = column.signalId ? sqTrendSeriesStore.findItem : sqTrendMetricStore.findItem;
      const potentialItem = itemFinder?.(potentialItemId);
      return {
        ...column,
        item: potentialItem,
        isStartOrEndColumn: _.includes([COLUMNS_AND_STATS.startTime.key, COLUMNS_AND_STATS.endTime.key], column.key),
      };
    });

    const columnIdToAutoHeight: Record<string, boolean> = {};

    const getNonSignalColumnKey = (column: AdornedColumn) =>
      _.includes(CONDITION_EXTRA_COLUMNS, column.key) ? i18next.t(column.shortTitle) : column.key;
    const getColumnName = (column: AdornedColumn) => {
      const potentialSignalStatName = column.signalId
        ? `${column.item.name} ${i18next.t(column.shortTitle)}`
        : undefined;
      const potentialMetricName = column.metricId ? column.item.name : undefined;
      const potentialStartOrEndName = column.isStartOrEndColumn ? i18next.t(column.shortTitle) : undefined;
      return potentialSignalStatName ?? potentialMetricName ?? potentialStartOrEndName ?? getNonSignalColumnKey(column);
    };

    const getColumnUnits = (column: AdornedColumn) => {
      const potentialSignalUnits = column.signalId ? _.find(rawHeaders, { name: column.key })?.units : undefined;
      const potentialMetricUnits = column.metricId
        ? _.find(rawHeaders, { name: itemColumnsMap[column.item.id].value.key })?.units
        : undefined;
      return potentialSignalUnits ?? potentialMetricUnits;
    };

    const isStringColumn = (column: AdornedColumn) => {
      const isStringSignalStat = _.find(rawHeaders, { name: column.key })
        ? _.find(rawHeaders, { name: column.key }).type === STRING_UOM
        : undefined;
      const isStringMetric = column.metricId ? isStringSeriesUtil(column.item) : undefined;
      return !!(isStringSignalStat || isStringMetric);
    };

    const headers = columns.map<ConditionTableHeader>((column, index) => ({
      key: column.key,
      name: getColumnName(column),
      units: getColumnUnits(column),
      isStringColumn: isStringColumn(column),
    }));

    const formatValue = (rawValue, item, column, capsuleId: string) => {
      // replace enums with the string representation
      const match = rawValue ? rawValue.toString().match(ENUM_REGEX) : false;
      if (match) {
        return match[2];
      }

      const value = formatMetricValue(rawValue, item?.formatOptions ?? {});
      if (
        (columnIdToAutoHeight[column.key] === undefined || columnIdToAutoHeight[capsuleId] === undefined) &&
        value.includes('\n')
      ) {
        columnIdToAutoHeight[column.key] = true;
        columnIdToAutoHeight[capsuleId] = true;
      }

      return value;
    };

    const buildConditionTableValue = (
      column: AdornedColumn,
      row: CapsuleFormulaTableRow,
      capsuleId: string,
    ): ConditionTableValue => {
      if (column.metricId) {
        const item = column.item;
        const itemColumns = itemColumnsMap[item.id];
        const formulaItemId = item.id;
        const itemId = row[itemColumns.itemId.key];
        const rawValue = row[itemColumns.value.key];
        const value = formatValue(rawValue, item, column, capsuleId);
        const priorityColor = row[itemColumns.priorityColor.key];

        return {
          itemId,
          formulaItemId,
          value,
          priorityColor,
          rawValue,
        };
      }

      return {
        value:
          column.key === SeeqNames.Properties.Duration
            ? getDuration(row)
            : formatValue(row[column.key], column.item, column, capsuleId),
        rawValue: row[column.key],
      };
    };

    const getDuration = (row: CapsuleFormulaTableRow) => {
      // Duration is a "hidden" property on capsules that we expose, but it is more accurate to compute it
      const duration = getCapsuleDuration(row.startTime, row.endTime);
      return _.isNil(duration) ? NULL_PLACEHOLDER : formatDuration(duration);
    };

    const rowIdCount = new Map<string, number>();
    const capsules = table.map<ConditionTableCapsule>((row) => {
      let rowId = `capsule-${row.startTime}-${row.endTime}`;
      rowIdCount.set(rowId, (rowIdCount.get(rowId) ?? 0) + 1);
      if (rowIdCount.get(rowId) > 1) {
        rowId = `${rowId}-${rowIdCount.get(rowId)}`;
      }

      const values = _.map(columns, (column) => {
        // Start and end use the values from the capsule
        if (column.isStartOrEndColumn) {
          const value = row[column.key] ?? null;

          return { value, rawValue: value };
        }

        return buildConditionTableValue(column, row, rowId);
      });

      return {
        startTime: row.startTime,
        endTime: row.endTime,
        id: rowId,
        property: row[customPropertyName],
        values,
      };
    });

    this.state.set(['columnIdToAutoHeight', TableBuilderMode.Condition], columnIdToAutoHeight);
    this.updateOtherColumns(TableBuilderMode.Condition, capsules);

    const tableData: ConditionTableData = { headers, capsules };
    this.state.set('fetchFailedMessage', undefined);
    this.state.set(['tableData', TableBuilderMode.Condition], tableData);
  }

  /**
   *  Sets table to be transposed (or not).
   *  If the table is not transposed (the initial default), then the time ranges will be shown in a row on top of
   *  the table.
   *  If the table is transposed, then the time ranges will be shown in a column on the left side of the table.
   *
   * @param {Object} payload - Object container
   * @param {boolean} payload.transposed - true if table is transposed, false if not
   */
  setIsTransposed(payload) {
    this.state.set(['isTransposed', this.state.get('mode')], payload.isTransposed);
  }

  /**
   *  Sets the asset over which the table will be run. If set it will be passed to the backend and the table
   *  formula will then run for each child asset and the results aggregated into a single table.
   *
   * @param {Object} payload - Object container
   * @param {string|undefined} payload.assetId - The ID of the parent asset or undefined to unset
   */
  setAssetId(payload) {
    this.state.set(['assetId', this.state.get('mode')], payload.assetId);
  }

  getAssetId(): string | undefined {
    return this.state.get('assetId', this.state.get('mode'));
  }

  getIsHomogenizeUnits() {
    return this.state.get('isHomogenizeUnits', this.state.get('mode'));
  }

  setIsHomogenizeUnits(payload) {
    this.state.set(['isHomogenizeUnits', this.state.get('mode')], payload.homogenizeUnits);
  }

  setIsMigrating(payload) {
    this.state.set('isMigrating', payload.isMigrating);
  }

  setUseSignalColorsInChart(payload) {
    this.state.set('useSignalColorsInChart', payload.useSignalColorsInChart);
  }

  setIsTableStriped(payload) {
    this.state.set(['isTableStriped', this.state.get('mode')], payload.isTableStriped);
  }

  /**
   * Sets a message to indicate an error fetching the data. Clears the existing data since it is assumed to be
   * invalid.
   *
   * @param fetchFailedMessage - The error message
   * @param mode - The mode in which it failed
   * @param apiMessage - The error message from the API
   */
  setFetchFailedMessage({ fetchFailedMessage, mode, apiMessage }) {
    this.state.set('fetchFailedMessage', {
      message: fetchFailedMessage,
      apiMessage: isBackendRowsLimitError(apiMessage) ? i18next.t('TREND_ROWS_LIMIT_ERROR') : apiMessage,
    });
    if (mode === TableBuilderMode.Simple) {
      this.state.set(['tableData', TableBuilderMode.Simple], []);
    } else {
      this.state.set(['tableData', TableBuilderMode.Condition], {
        headers: [],
        capsules: [],
      });
    }
  }

  /**
   * Removes items.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} payload.items - An array of items to remove
   */
  removeItems(payload) {
    const removedIds = _.map(payload.items, 'id');
    _.forEach(removedIds, (id) => {
      this.removeSort(id);
      const rowIndex = _.findIndex(this.chartViewSettings.rows, (rows) => rows === id);
      if (rowIndex > -1) {
        this.state.splice(['chartView', 'settings', 'rows'], [rowIndex, 1]);
      }
    });

    const removeRelatedData = () => {
      if (_.isEmpty(_.reject(this.getTableItemsProcess(), ({ id }) => _.includes(removedIds, id)))) {
        this.state.unset(['assetId', this.state.get('mode')]);
      }

      _.chain(this.getColumns())
        .filter(({ signalId, metricId }) => _.includes(removedIds, signalId) || _.includes(removedIds, metricId))
        .forEach(({ key }) => this.removeColumn({ key }))
        .value();
    };

    removeRelatedData();
    const originalMode = this.state.get('mode');
    const otherMode = originalMode === TableBuilderMode.Simple ? TableBuilderMode.Condition : TableBuilderMode.Simple;
    try {
      this.state.set('mode', otherMode);
      removeRelatedData();
    } finally {
      this.state.set('mode', originalMode);
    }
  }

  /**
   * Swaps out the specified items from one asset for the variants based off another asset.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
   *   values are the corresponding swapped in ids.
   * @param {Object} payload.outAsset - Asset that was swapped out
   * @param {String} payload.outAsset.id - The ID of the asset to swapped out
   * @param {String} payload.inAsset.name - The name of the asset that was swapped out
   * @param {Object} payload.inAsset - Asset that was swapped in
   * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
   * @param {String} payload.inAsset.name - The name of the asset that was swapped in
   */
  swapItems(payload) {
    _.forEach(payload.swaps, (swappedInId, swappedOutId) => {
      _.forEach([TableBuilderMode.Condition, TableBuilderMode.Simple], (mode) => {
        _.forEach(this.state.get('columns', mode), (column, index) => {
          if (column.signalId === swappedOutId) {
            this.state.merge(['columns', mode, index], {
              signalId: swappedInId,
              key: this.getColumnKeyFromSignalId(column, swappedInId),
            });
          }
          if (column.metricId === swappedOutId) {
            this.state.merge(['columns', mode, index], {
              metricId: swappedInId,
              key: swappedInId,
            });
          }
        });
      });
    });
  }

  /**
   * Enables the Metric Value column if a new simple metric was created and its statistic is not already displayed
   * in the table. It only happens if the table view is active and table mode is simple.
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - Simple metric identifier
   * @param {ThresholdMetricInputV1} payload.definition - Simple metric definition
   */
  handleSimpleMetricCreated(payload) {
    const isMetricValueEnabled = _.some(this.columns, ['key', COLUMNS_AND_STATS.metricValue.key]);
    const isAggregationFunctionAlreadyDisplayed = _.some(this.columns, [
      'stat',
      payload.definition.aggregationFunction,
    ]);

    if (
      sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE ||
      !this.getIsSimpleMode() ||
      isMetricValueEnabled ||
      isAggregationFunctionAlreadyDisplayed
    ) {
      return;
    }

    this.addColumn({ column: { key: COLUMNS_AND_STATS.metricValue.key } });
    infoToast({
      messageKey: 'TABLE_BUILDER.METRIC_VALUE_COLUMN_AUTOMATICALLY_ENABLED',
    });
  }

  /**
   * Removes metric information from the specified column. If an item is removed, all metric info entries are
   * removed. If a metric is removed, the corresponding metric info is removed.
   * @param removedIds - the item ids to look for
   * @param columnIndex - the column index
   */
  removeColumnMetricInfo(removedIds: string[], columnIndex: number) {
    const cursor = this.state.select('columns', this.state.get('mode'), columnIndex, 'metrics');
    _.forEach(cursor.get(), (metricInfo, itemId) => {
      if (_.includes(removedIds, metricInfo.id) || _.includes(removedIds, itemId)) {
        cursor.unset(itemId);
      }
    });
  }

  /**
   * Sets the chart view to be enabled or not
   * @param payload - payload.enabled boolean if chart view is enabled
   */
  setChartView(payload) {
    this.state.set(['chartView', 'enabled'], payload.enabled);
  }

  /**
   * Sets the chart view to be enabled or not
   * @param payload - payload.enabled boolean if chart view is enabled
   */
  setConditionChartView(payload) {
    this.state.set(['chartView', 'conditionEnabled'], payload.conditionEnabled);
  }

  /**
   * Sets flag to expande or collapse all row groups
   * @param payload - payload.areAllRowsExpanded true means expand false means collapse undefined means noop*/
  setAreAllRowsExpanded(payload: { areAllRowsExpanded: boolean }) {
    this.state.set('areAllRowsExpanded', payload.areAllRowsExpanded);
  }

  /**
   * Settings for the chart view
   * @param payload - payload.settings object with settings for the chart view
   */
  setChartViewSettings(payload) {
    this.state.select(['chartView', 'settings']).merge(payload.settings);
  }

  /**
   * Settings for the condition table chart view
   * @param payload - payload.conditionSettings object with settings for the ag grid chart configuration
   */
  setChartViewConditionSettings(payload: { conditionSettings?: ChartModel }) {
    this.state.select(['chartView', 'conditionSettings']).set(payload.conditionSettings);
  }

  /**
   *  Set the distinctStringValueMap from data for the Simple Table
   *
   * @param payload object container for arguments
   * @param payload.stringValueTables: array of the tables, each containing distinct string values for a column
   * @param payload.columnKeysNamesList: list of objects containing columnKeys (for the displayed table) and
   *  columnNames (for the tables we got back from the calc engine) - one columnKey may correspond to more than
   *  one columnName
   */
  setSimpleDistinctStringValues(payload: { stringValueTables: any[]; columnKeysNamesList: any[] }) {
    if (_.isEmpty(payload.stringValueTables) || !this.getIsSimpleMode()) {
      return;
    }
    const distinctStringValueMap = _.cloneDeep(this.state.get('distinctStringValueMap', TableBuilderMode.Simple));
    for (let i = 0; i < payload.stringValueTables.length; i++) {
      const tableData = payload.stringValueTables[i].data;
      const tableHeaders = payload.stringValueTables[i].headers;
      const { columnKey, columnNames } = payload.columnKeysNamesList[i];
      let distinctValues = [];
      _.forEach(columnNames, (columnName) => {
        const index = _.findIndex(tableHeaders, { name: columnName });
        distinctValues = distinctValues.concat(_.chain(tableData).map(`[${index}]`).reject(_.isNil).value());
      });
      distinctStringValueMap[columnKey] = distinctValues;
    }
    _.forEach(_.keys(distinctStringValueMap), (existingKey) => {
      if (!_.includes(_.map(payload.columnKeysNamesList, 'columnKey'), existingKey)) {
        delete distinctStringValueMap[existingKey];
      }
    });
    this.state.set(['distinctStringValueMap', TableBuilderMode.Simple], distinctStringValueMap);
  }

  /**
   *  Set the distinctStringValueMap from data for the Condition Table
   *
   * @param payload object container for arguments
   * @param payload.stringValueTables: array of the tables, each containing distinct string values for a column
   * @param payload.columnKeysNamesList: list of objects containing columnKeys (for the displayed table) and
   *  columnNames (for the tables we got back from the calc engine)
   */
  setConditionDistinctStringValues(payload: {
    stringValueTable;
    columnKeyAndName: { columnKey: string; columnName: string };
  }) {
    const tableData = payload.stringValueTable.data.table;
    const columnKey = payload.columnKeyAndName.columnKey;
    const columnName = payload.columnKeyAndName.columnName;
    this.state.set(['distinctStringValueMap', TableBuilderMode.Condition, columnKey], _.map(tableData, columnName));
  }

  enableRowGroup(payload: { key: string }) {
    if (this.state.get(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'grouping'])) {
      return;
    }
    this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'grouping'], true);
    const currentHighestRowGroupOrder: number | undefined = _.max(
      this.state
        .get(['columns', this.state.get('mode')])
        .map((column) => column.rowGroupOrder)
        .filter((order) => _.isNumber(order)),
    );
    const newRowGroupIndex = (currentHighestRowGroupOrder ?? -1) + 1;
    this.state.set(
      ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'rowGroupOrder'],
      newRowGroupIndex,
    );
  }

  disableRowGroup(payload: { key: string }) {
    const index = this.getColumnIndex(payload.key, true);
    if (index > -1) {
      this.state.unset(['columns', this.state.get('mode'), index, 'grouping']);
      // We don't need to adjust the ordering of other columns because ag-grid uses relative ordering. So if the only
      // columns left have orders of 1, 3, 5, they will be grouped in that order.
      this.state.unset(['columns', this.state.get('mode'), index, 'rowGroupOrder']);
    }
  }

  setRowGroupShow(payload: { fullPath: string; expanded: boolean }) {
    const index = _.findIndex(
      this.state.get(['rowGroupPaths', this.state.get('mode')]),
      (path) => path === payload.fullPath,
    );
    if (payload.expanded && index < 0) {
      this.state.push(['rowGroupPaths', this.state.get('mode')], payload.fullPath);
    } else if (!payload.expanded && index > -1) {
      this.state.splice(['rowGroupPaths', this.state.get('mode')], [index, 1]);
    }
  }

  toggleRowGrouping() {
    if (this.autoGroupColumn) {
      this.state.unset(['autoGroupColumn', this.state.get('mode')]);
    } else {
      this.state.set(['autoGroupColumn', this.state.get('mode')], {});
    }
  }

  setRowGroupOrder(payload: { order: string[] }) {
    payload.order.forEach((key, index) => {
      this.state.set(['columns', this.state.get('mode'), this.getColumnIndex(key), 'rowGroupOrder'], index);
    });
  }

  setAggregationFunction(payload: { key: string; aggregationFunction: AgGridAggregationFunction }) {
    if (payload.aggregationFunction === 'none') {
      this.state.unset(['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'aggregationFunction']);
    } else {
      this.state.set(
        ['columns', this.state.get('mode'), this.getColumnIndex(payload.key), 'aggregationFunction'],
        payload.aggregationFunction,
      );
    }
  }
}
