import { sqContextApi, sqGraphQLApi, sqItemsApi } from '@/sdk';
import { setImpactReportCategories, setImpactReportTableId } from '@/impactReports/impactReport.actions';
import { availableStages, ImpactType, stageIcons, Stages } from '@/impactReports/impactReport.types';
import { t } from 'i18next';
import { getColumnDefinitions, getImpactReportByWorksheet } from '@/impactReports/tiles/table.utilities';
import { SelectOption } from '@/formbuilder/SelectFormComponent';
import { ColDef } from '@ag-grid-community/core';
import { ScalarPropertyV1 } from 'sdk/model/ScalarPropertyV1';
import { errorToast } from '@/utilities/toast.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { IMPACT_REPORT_CATEGORY_PREFIX, ImpactReportCategory } from './impactReport.types';
import { sqImpactReportStore } from '@/core/core.stores';

export function getCustomCategoryTitle(category: ImpactReportCategory) {
  return category?.category?.name?.substring(IMPACT_REPORT_CATEGORY_PREFIX.length) ?? '';
}

export function stageDisplayStringByIndex(stageIndex: number) {
  return t(`IMPACT_REPORT.${availableStages[stageIndex]?.toUpperCase()}`);
}

export function getStageDisplayString(stage: Stages) {
  if (!stage) return '';
  if (t(`IMPACT_REPORT.${stage.toUpperCase()}`) === `IMPACT_REPORT.${stage.toUpperCase()}`) return stage;
  return t(`IMPACT_REPORT.${stage.toUpperCase()}`);
}

export function getImpactStageIcon(stage: Stages) {
  const index = availableStages.indexOf(stage);
  if (index > -1 && index <= stageIcons.length) {
    return stageIcons[availableStages.indexOf(stage)];
  } else {
    return stageIcons[0];
  }
}

export function getCategoryDisplayString(
  categoryId: string,
  categories: { value: string; label: string }[] | undefined,
  fallback = '',
) {
  const category = categories?.find((category) => category.value === categoryId);
  return category ? category.label : fallback;
}

export async function fetchCategories() {
  const { data } = await sqContextApi.findLabelCategories({});
  const categories = data?.categories?.filter((category) => category.name?.startsWith('VCC_'));
  if (!categories) return;
  const allOptions: ImpactReportCategory[] = [];
  await Promise.all(
    categories.map(async (category) => {
      const labelResponse = await sqContextApi.findLabels({ categoryId: category.id! });
      const labels = labelResponse.data.labels;
      const options = labels?.map((label) => ({ value: label.id!, label: label.name! }));
      allOptions.push({ category, options });
    }),
  );
  return allOptions;
}

export function getStageIndex(stage: Stages) {
  return availableStages.indexOf(stage.toLowerCase().trim() as Stages);
}

export function getAvailableAggregationSelectOptions(
  categories: ImpactReportCategory[] | undefined,
): SelectOption<string | undefined>[] {
  const options = getColumnDefinitions(categories)
    .filter((column: ColDef<string, string>) => column.context?.canAggregateBy)
    .sort((a: ColDef<string, string>, b: ColDef<string, string>) => {
      if (a.headerName! < b.headerName!) return -1;
      if (a.headerName! > b.headerName!) return 1;
      return 0;
    })
    .map((columnDef) => ({
      label: columnDef.headerName!,
      value: columnDef.field!,
    }));

  return [{ label: t('IMPACT_REPORT.NONE'), value: 'none' }, ...options];
}

/**
 * This function fetches all the categories that are available to a user to "categorize" their Impact report
 * These categories are Context Labels and we need to ensure we fetch them before we attempt to display the table as
 * we need them to properly create and map the columns for categories.
 */
export async function initializeImpactReportCategories() {
  const categories = await fetchCategories();
  setImpactReportCategories(categories);
}

/**
 * Fetches the Impact Report Table ID from the server
 * If the table does not exist, it creates it
 */
export async function getImpactReportTableId(): Promise<string | undefined> {
  const { data } = await sqItemsApi.searchItems({
    filters: [`name==${SeeqNames.ImpactReport.ImpactTableName}`],
    types: ['TableDefinition'],
  });
  const tableId = data.items && data.items[0].id;
  tableId && setImpactReportTableId(tableId);
  console.log('Value Capture Table ID:', tableId);
  return tableId;
}

export async function addOrUpdateImpactReport(tableId: string, impactReport: ScalarPropertyV1[]) {
  return sqGraphQLApi.graphql({
    query: 'mutation PublishEvents($events: RowEventsInput!) { publishEvent(rowEvents: $events) }',
    variables: {
      events: { tableDefinitionId: tableId, events: [{ genericEvent: { properties: impactReport } }] },
    },
  });
}

export function formatNumberAsValue(num: number): string {
  if (!Number.isFinite(num)) return '';
  if (num >= 1_000_000) {
    return `$${(num / 1_000_000).toFixed(3)}M`;
  } else if (num >= 1_000) {
    return `$${(num / 1_000).toFixed(0)}K`;
  } else {
    return `$${new Intl.NumberFormat('en-US').format(Math.round(num))}`;
  }
}

export function getImpactTypeDisplayString(impactType: ImpactType) {
  switch (impactType) {
    case 'continuous':
      return t('IMPACT_REPORT.CONTINUOUS');
    case 'oneTime':
      return t('IMPACT_REPORT.ONE_TIME');
    case 'customCalc':
      return t('IMPACT_REPORT.CUSTOM_CALC');
    default:
      return impactType;
  }
}

export function getAllSupportedBoxTypes() {
  return [
    {
      label: t('IMPACT_REPORT.TOTAL_USE_CASES'),
      value: 'useCaseCountBox',
      title: 'IMPACT_REPORT.TOTAL_USE_CASES',
      property: 'useCaseCount',
      id: 'useCaseCountBox',
    },
    {
      label: t('IMPACT_REPORT.TOTAL_IMPACT'),
      value: 'totalImpactBox',
      title: 'IMPACT_REPORT.TOTAL_IMPACT',
      property: 'overallImpact',
      id: 'totalImpactBox',
    },
    {
      label: t('IMPACT_REPORT.BIGGEST_SINGLE_IMPACT'),
      value: 'biggestImpactBox',
      title: 'IMPACT_REPORT.BIGGEST_SINGLE_IMPACT',
      property: 'biggestImpact',
      id: 'biggestImpactBox',
    },
  ];
}

export async function deleteCategory(category: ImpactReportCategory) {
  try {
    category.options &&
      (await Promise.all(
        category.options.map(async (option) => {
          try {
            await sqContextApi.deleteLabel({ labelId: option.value });
          } catch (e) {
            console.error(e);
            errorToast({ messageKey: 'Failed to delete associated label' });
          }
        }),
      ));
    await sqContextApi.deleteLabelCategory({ categoryId: category.category.id! });
  } catch (error) {
    console.error(error);
    errorToast({ messageKey: 'Failed to delete category' });
  }
  await initializeImpactReportCategories();
}

export function goToWorksheet(worksheetId: string) {
  window.open(`/view/${worksheetId}`, '_blank');
}

export async function getImpactReportForWorksheet(workbookId: string | undefined, worksheetId: string | undefined) {
  if (workbookId && worksheetId) {
    let tableId = sqImpactReportStore.tableId;
    if (!tableId) {
      tableId = await getImpactReportTableId();
    }
    if (!tableId) return;
    return await getImpactReportByWorksheet(tableId, worksheetId, workbookId);
  }

  return undefined;
}
